import { Controller } from "stimulus";
import Rails from "@rails/ujs";

export default class extends Controller {
  static targets = [
    "phone",
    "code",
    "number",
  ];

  connect() {
    this.setupFormValidation();

    $('#guest-count-popup')?.modal('show');
  }

  setupFormValidation() {
    $('#new-human').validetta({
      bubblePosition: 'bottom',
      bubbleGapTop: 10,
      bubbleGapLeft: -5,
      onValid: (e) => {
        e.preventDefault();

        if ($('#new-human-modal').data('showPopup') === false) {
          $('#new-human').off('submit').submit();
        } else {
          this.hideAndShowModals();
        }
      },
      onError: (e) => {
        e.preventDefault();
      },
    });
  }

  proceedForm(event) {
    event.preventDefault();

    $('#new-human').off('submit').submit();
  }

  update_phone() {
    const phone_number = this.codeTarget.value + this.numberTarget.value;
    this.phoneTarget.value = phone_number;
  }

  hideAndShowModals() {
    $('#new-human-modal').modal('hide');
    $('#change-guest-popup').modal('show');
  }
}
