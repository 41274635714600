import { Controller } from "stimulus"
import Rails from "@rails/ujs";

export default class extends Controller {
  static targets = [
    "handle",
    "tooltip",
    "slider",
    "sliderValue",
    "cycleSwitch",
    "plan",
    "locationsCounter",
    "essentialBlock",
    "growthBlock",
    "proBlock",
    "locationsInput",
    "locationsAmount",
    "guestsInput",
    "guestsAmount",
    "totalAmount",
    "essentialRadio",
    "growthRadio",
    "proRadio",
    "planName",
    "planPrice",
    "defaultPlan",
    "signUpButton"
  ]

  connect(){
    this.plans = JSON.parse(this.data.get('plans'))
    this.symbols = JSON.parse(this.data.get('symbols'))
    this.bracketFee = JSON.parse(this.data.get('bracket-fee'))
    this.brackets = JSON.parse(this.data.get('brackets'))
    this.currency = this.data.get('currency')
    this.base_url = this.data.get('base-url')
    this.selectedPlan = 'pro'
    this.bracketLabel = ["0", "1,000", "3,000", "5,000", "8,000", "10k", "15k", "20k", "25k", "30k", "35k", "40k", "45k", "50k", "55k", "60k", "65k", "70k", "75k", "80k", "85k", "90k", "95k", "100k+"];
    this.bracketIndex = 1;
    this.locationsCount = 1;
    this.monthlyBilling = false;

    this.setPrices();
    this.resetCalculator();
  }

  setTooltip(){
    // SET THE TOOLTIP POSITION AND VALUE

    this.bracketIndex = $(this.sliderValueTarget).val();
    var bracketLabel = this.bracketLabel[this.bracketIndex];
    var sliderLeft = $(this.sliderTarget).offset().left;
    var handleLeft = $(this.handleTarget).offset().left;

    $(this.tooltipTarget).text(bracketLabel);
    $(this.tooltipTarget).attr('style', 'display:block;left:' + (handleLeft - sliderLeft - 12.5 - 5) + 'px;');

    // MANAGE RECOMMENDED PLAN

    $(this.essentialBlockTarget).removeClass("highlighted");
    $(this.growthBlockTarget).removeClass("highlighted");
    $(this.proBlockTarget).removeClass("highlighted");
    if(this.bracketIndex < 2){
      $(this.essentialBlockTarget).addClass("highlighted");
    } else if (this.bracketIndex < 8){
      $(this.growthBlockTarget).addClass("highlighted");
    } else {
      $(this.proBlockTarget).addClass("highlighted");
    }
  }

  setPrices(){
    // CALCULATE AND SET PRICING
    console.log("Calculating pricing...");

    this.updateBillingCycle();

    var locationsCount = this.locationsCount;
    var bracketIndex = this.bracketIndex;
    var monthlyBilling = $(this.cycleSwitchTarget).prop('checked');

    console.log(monthlyBilling);

    $(this.planTargets).each(function(i, obj) {
      // PRICE OF FIRST LOCATION
      var priceFirst = parseFloat($(obj).data("first"));
      // PRICE OF EXTRA LOCATIONS UP TO 10
      var priceUpToTen = (priceFirst * 0.85);
      // PRICE OF EXTRA LOCATIONS BEYOND 10
      var priceBeyondTen = (priceFirst * 0.75);
      // INCREMENTAL BRACKET PRICE
      var bracketFee = $(obj).data("bracket-fee");
      // USER CURRENCY
      var currency = $(obj).data("currency");

      var priceExtraLocations = 0;
      if (locationsCount > 10) {
        priceExtraLocations = (9 * priceUpToTen) + ((locationsCount - 10) * priceBeyondTen);
      } else if (locationsCount <= 10) {
        priceExtraLocations = ((locationsCount - 1) * priceUpToTen);
      }

      var price = Math.floor(priceFirst + priceExtraLocations + (bracketFee * ((bracketIndex == 0) ? 0 : bracketIndex - 1)));

      if(monthlyBilling){
        $('.monthly').text("PER MONTH");
        $('.price-line-through').css('display', 'none');
      } else {
        // 2 months free for yearly billing
        $('.monthly').text("PER MONTH, BILLED YEARLY");
        var price = Math.floor((price * 10) / 12);
        $('.price-line-through').css('display', 'inline-block');
      }
      var priceElements = document.querySelectorAll('.del-price');
      priceElements.forEach(function(priceElement) {
      var delPrice = parseFloat(priceElement.textContent);
      var integerPrice = Math.floor(delPrice);
      priceElement.textContent = integerPrice;
      });

      $(obj).find(".price-change").text(price);
    });

    if(this.locationsCount == 1){
      $(this.locationsCounterTargets).text(this.locationsCount + " Location");
    } else {
      $(this.locationsCounterTargets).text(this.locationsCount + " Locations");
    }
  }

  removeLocation(){
    // DECREMENT LOCATION COUNTER

    if(this.locationsCount > 1){
      --this.locationsCount;
    }

    this.setPrices();
  };

  toggleCycle(){

    this.setPrices();
  };

  addLocation(){
    // INCREMENT LOCATION COUNTER

    ++this.locationsCount;

    this.setPrices();
  };

  newTrial(event) {
    const link = event.currentTarget;
    const billingCycle = this.cycleSwitchTarget.checked ? "monthly" : "yearly";
    const url = `${link.href}&billing_cycle=${billingCycle}`;
    link.href = url;
  }

  updateLocationsTotal = () => {
    let locationsInput = Number(this.locationsInputTarget.value)
    let locationsAmount = this.locationsAmountTarget
    let price = this.plans[this.selectedPlan][this.currency]

    price = (this.cycleSwitchTarget.checked ? (price) : ((price*10)/12))

    var amount = 0
    for (let i=locationsInput; i>=0; i--){
      if (i > 10){
        amount += (price - (price*(25/100)));
      } else if (i >= 2 && i <= 10) {
        amount += (price - (price*(15/100)));
      } else if (i < 1){
        amount += price;
      }
    }

    locationsAmount.value = amount
    locationsAmount.textContent = `${this.symbols[this.currency]} ${parseFloat(amount.toFixed(2))}`
    this.calculateTotal()
  }

  updateGuestsTotal = () => {
    let guestsInput = Number(this.guestsInputTarget.value)
    let guestsAmount = this.guestsAmountTarget
    let price = this.bracketFee[this.currency]
    let brackets = this.brackets

    var multiplier = 0
    for (let key in brackets){
      if (guestsInput > brackets[key][0] && guestsInput <= brackets[key][1]){
        multiplier = key
      }
    }
    let amount = price * multiplier

    guestsAmount.value = amount
    guestsAmount.textContent = `${this.symbols[this.currency]} ${parseFloat(amount.toFixed(2))}`
    this.calculateTotal()
  }

  calculateTotal = () => {
    let locationsAmount = this.locationsAmountTarget.value
    let guestsAmount = this.guestsAmountTarget.value
    let totalAmount = this.totalAmountTarget

    let total_amount = parseFloat(locationsAmount) + parseFloat(guestsAmount)

    totalAmount.textContent = `${this.symbols[this.currency]} ${parseFloat(total_amount.toFixed(2))}`
  }

  setSelectedPlan(event) {
    const currentElem = event.currentTarget;
    const selectedPlan = currentElem.dataset.plan;

    const planInput = currentElem.querySelector("input[type='radio']");
    planInput.checked = true;

    this.selectedPlan = selectedPlan

    this.updateSelectedPlanUI(selectedPlan);
    this.updateSelectedPlan();
  }

  updateSelectedPlanUI(selectedPlan) {
    const buttons = {
      essential: this.essentialRadioTarget,
      growth: this.growthRadioTarget,
      pro: this.proRadioTarget,
    };

    const planBlocks = {
      essential: this.essentialBlockTarget,
      growth: this.growthBlockTarget,
      pro: this.proBlockTarget,
    };

    Object.values(planBlocks).forEach(planTarget => {
      planTarget.classList.toggle("highlighted", planTarget.classList.contains(selectedPlan));
    });

    Object.keys(buttons).forEach((plan) => {
      buttons[plan].lastElementChild.textContent = plan === selectedPlan ? "Selected" : "Select";
    });
  }

  updateSelectedPlan = () => {
    let planName = this.planNameTarget
    let planPrice = this.planPriceTarget
    let signUpButtons = this.signUpButtonTargets
    let price = this.plans[this.selectedPlan][this.currency]

    price = parseFloat(this.cycleSwitchTarget.checked ? (price) : ((price*10)/12)).toFixed(2)

    signUpButtons.forEach((signUpButton) => {
      signUpButton.href = `${this.base_url}/trial?plan=${this.selectedPlan}&billing_cycle=${
        this.cycleSwitchTarget.checked ? "monthly" : "yearly"
      }`;
    });

    planName.textContent = this.selectedPlan.toUpperCase()
    planPrice.textContent = `${this.symbols[this.currency]} ${price}`
    this.updateLocationsTotal()
  }

  updateBillingCycle = () => {
    this.updateSelectedPlan()
  }

  resetCalculator = () => {
    let defaultPlan = this.defaultPlanTarget
    defaultPlan.checked = true

    this.updateLocationsTotal();
    this.updateGuestsTotal();
  }
}
