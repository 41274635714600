import { Controller } from "stimulus"
import Rails from "@rails/ujs";

export default class extends Controller {

  static targets = [

  ]

  connect() {
    const controller = this
    const url = controller.element.getAttribute("data-url")
    const element_id = controller.element.getAttribute("id")
    $.ajax({
      method: "GET",
      url: url,
      success: function(res){
        $.ajax({
          method: "POST",
          url: "interactions/render_template_preview.js",
          data: {element_id: element_id, json: JSON.stringify(res)}
        })
      }
    })

  }


}
