import { Controller } from "stimulus"
import Rails from "@rails/ujs";

export default class extends Controller {
  static targets = [
    "html"
  ]

  initialize(){
    this.update_preview()
  }

  update_preview(){
    const html = this.htmlTarget.value
    const frame = document.querySelector('iframe').contentWindow.document
    frame.src = "about:blank"
    frame.open()
    frame.write(html)
    frame.close()
    document.querySelector("#interaction_body").value = html

    const code_editor = document.querySelector("#highlighting-content")
    code_editor.innerHTML = html.replace(new RegExp("<", "g"), "&lt;")

    document.querySelector("#highlighting").scrollTop = this.htmlTarget.scrollTop
    document.querySelector("#highlighting").scrollLeft = this.htmlTarget.scrollLeft

    Prism.highlightElement(code_editor)

    document.querySelector(".template_pull_body").value = html
  }

  scroll_code(){
    const code_editor = document.querySelector("#highlighting")
    code_editor.scrollTop = this.htmlTarget.scrollTop
    code_editor.scrollLeft = this.htmlTarget.scrollLeft
  }

  check_tab(event) {
    const code = this.htmlTarget.value
    const key = event.which
    if(key == 9){
      event.preventDefault()
      let before_tab = code.slice(0, this.htmlTarget.selectionStart)
      let after_tab = code.slice(this.htmlTarget.selectionEnd, this.htmlTarget.value.length)
      let cursor_pos = this.htmlTarget.selectionEnd + 2
      this.htmlTarget.value = before_tab + "  " + after_tab
      this.htmlTarget.selectionStart = cursor_pos
      this.htmlTarget.selectionEnd = cursor_pos
    }
  }

}
