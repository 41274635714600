import { Controller } from "stimulus"
import Rails from "@rails/ujs";

export default class extends Controller {
  static targets = [
    "slide",
  ]

  initialize() {
    console.log("asd");
  }

  switchSlide() {
    console.log("switch");
  }
}
