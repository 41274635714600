import { Controller } from "stimulus"
//import Rails from "@rails/ujs";
import consumer from "channels/consumer"

export default class extends Controller {
  static targets = []

  connect(){
    console.log("connected")
    this.subscription = consumer.subscriptions.create({ channel: "LocationChannel", id: this.data.get("id")}, {
      connected: this._connected.bind(this),
      disconnected: this._disconnected.bind(this),
      received: this._received.bind(this)
    })
    this.minutes_int = parseInt(this.element.getAttribute("data-minutes-since-update"))
    setInterval(this.incrementTime, 60000, this)
    if (this.element.classList.contains("active")){
      this.status = "Online"
    } else if (this.element.classList.contains("inactive")){
      this.status = "Offline"
    } else {
      this.status = "Unknown"
    }
  }

  incrementTime(controller){
    controller.minutes_int += 1
    if (controller.status == "Online" || controller.status == "Offline"){
      controller.element.setAttribute("data-original-title", `${controller.status} as of ${controller.minutes_int} minutes ago`)
    }
  }

  disconnect(){
    consumer.subscriptions.remove(this.subscription)
  }

  _connected(){

  }

  _disconnected(){

  }

  _received(data){
    console.log(data)
    this.minutes_int = 0
    if (data.status == 1){
      this.status = "Online"
      if (this.element.classList.contains("inactive") || this.element.classList.contains("unknown-status")){
        this.element.classList.remove("inactive")
        this.element.classList.remove("unknown-status")
        this.element.classList.add("active")
        this.element.setAttribute("data-original-title", "Online as of 0 minutes ago")
      }
    } else if (data.status == 2){
      this.status = "Unknown"
      if (this.element.classList.contains("active") || this.element.classList.contains("inactive")){
        this.element.classList.remove("inactive")
        this.element.classList.add("unknown-status")
        this.element.classList.remove("active")
        this.element.setAttribute("data-original-title", "This location has had no activity for 3 hours")
      }
    } else {
      this.status = "Offline"
      if (this.element.classList.contains("active") || this.element.classList.contains("unknown-status")){
        this.element.classList.add("inactive")
        this.element.classList.remove("active")
        this.element.classList.remove("unknown-status")
        this.element.setAttribute("data-original-title", "Offline as of 0 minutes ago")
      }
    }
  }

}
