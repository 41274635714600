import { Controller } from "stimulus"
import Rails from "@rails/ujs";

export default class extends Controller {
  static targets = ["option_container", "option", "mode", "toggle"]

  remove(){
    let parent = this.element.parentElement
    this.element.remove()
    parent.querySelectorAll("label").forEach(function(elem, index){
      elem.innerHTML = `Option ${index + 1}`
    })
  }

}
