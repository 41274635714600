import { Controller } from "stimulus"
import Rails from "@rails/ujs";

export default class extends Controller {

  static targets = [

  ]

  initialize() {
    //
  }

  remove(event){
    event.stopPropagation()
    event.preventDefault()

    swal({
      title: 'Are you sure?',
      text: "Archiving will unsubscribe this guest from all communications and could impact your billing",
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, archive this guest!',
      cancelButtonText: 'Cancel',
      confirmButtonClass: 'btn btn-lg btn-danger waves-effect waves-light m-t-20',
      cancelButtonClass: 'btn btn-lg btn-transparent waves-effect waves-light m-l-10 m-t-20',
      buttonsStyling: false
    }).then(function () {
      document.getElementById('human-remove-action').click()
    }, function (dismiss) {
      if (dismiss === 'cancel') {
        // window.location.href = link;
      }
    })
  }

  revert(event){
    event.stopPropagation()
    event.preventDefault()

    swal({
      title: 'Are you sure?',
      text: "Unarchiving this guest will allow you to communicate with them again and could impact your billing",
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, Unarchive this guest',
      cancelButtonText: 'Cancel',
      confirmButtonClass: 'btn btn-lg btn-success waves-effect waves-light m-t-20',
      cancelButtonClass: 'btn btn-lg btn-transparent waves-effect waves-light m-l-10 m-t-20',
      buttonsStyling: false
    }).then(function () {
      document.getElementById('human-revert-action').click()
    }, function (dismiss) {
      if (dismiss === 'cancel') {
        // window.location.href = link;
      }
    })
  }

  block(event){
    event.stopPropagation()
    event.preventDefault()

    swal({
      title: 'Are you sure?',
      text: "Blocking will prevent this guest from connecting",
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, block this guest!',
      cancelButtonText: 'Cancel',
      confirmButtonClass: 'btn btn-lg btn-danger waves-effect waves-light m-t-20',
      cancelButtonClass: 'btn btn-lg btn-transparent waves-effect waves-light m-l-10 m-t-20',
      buttonsStyling: false
    }).then(function () {
      document.getElementById('human-block-action').click()
    }, function (dismiss) {
      if (dismiss === 'cancel') {
        // window.location.href = link;
      }
    })
  }

  unblock(event){
    event.stopPropagation()
    event.preventDefault()

    swal({
      title: 'Are you sure?',
      text: "Unblocking will allow this guest to connect again",
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, unblock this guest!',
      cancelButtonText: 'Cancel',
      confirmButtonClass: 'btn btn-lg btn-primary waves-effect waves-light m-t-20',
      cancelButtonClass: 'btn btn-lg btn-transparent waves-effect waves-light m-l-10 m-t-20',
      buttonsStyling: false
    }).then(function () {
      document.getElementById('human-unblock-action').click()
    }, function (dismiss) {
      if (dismiss === 'cancel') {
        // window.location.href = link;
      }
    })
  }

  unsubscribe(event) {
    event.stopPropagation()
    event.preventDefault()

    swal({
      title: 'Are you sure?',
      text: "This will unsubscribe this guest from receiving emails",
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, unsubscribe this guest!',
      cancelButtonText: 'Cancel',
      confirmButtonClass: 'btn btn-lg btn-danger waves-effect waves-light m-t-20',
      cancelButtonClass: 'btn btn-lg btn-transparent waves-effect waves-light m-l-10 m-t-20',
      buttonsStyling: false
    }).then(function () {
      document.getElementById('human-unsubscribe-action').click()
    }, function (dismiss) {
      if (dismiss === 'cancel') {
        // window.location.href = link;
      }
    })
  }

  subscribe(event){
    event.stopPropagation()
    event.preventDefault()

    swal({
      title: 'Are you sure?',
      text: "This will subscribe this guest to your emails again",
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, subscribe this guest!',
      cancelButtonText: 'Cancel',
      confirmButtonClass: 'btn btn-lg btn-primary waves-effect waves-light m-t-20',
      cancelButtonClass: 'btn btn-lg btn-transparent waves-effect waves-light m-l-10 m-t-20',
      buttonsStyling: false
    }).then(function () {
      document.getElementById('human-subscribe-action').click()
    }, function (dismiss) {
      if (dismiss === 'cancel') {
        // window.location.href = link;
      }
    })
  }

  forgetDevice(event) {
    event.stopPropagation()
    event.preventDefault()
    const index = event.currentTarget.getAttribute('data-index')

    swal({
      title: 'Are you sure?',
      text: "This device will no longer be connected automatically.",
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, forget this device!',
      cancelButtonText: 'Cancel',
      confirmButtonClass: 'btn btn-lg btn-primary waves-effect waves-light m-t-20',
      cancelButtonClass: 'btn btn-lg btn-transparent waves-effect waves-light m-l-10 m-t-20',
      buttonsStyling: false
    }).then(function () {
      document.getElementById('forget-device-action-' + index).click()
    }, function (dismiss) {
      if (dismiss === 'cancel') {
        // window.location.href = link;
      }
    });
  }


}
