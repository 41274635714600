import { Controller } from "stimulus"

export default class extends Controller {

  static targets = [
    "locations"
  ]

  select(event){
    const location_id = event.currentTarget.getAttribute("data-location-id")
    jQuery(this.locationsTarget).modal('hide')
    jQuery(`#location-${location_id}-modal`).modal('show')
  }

  back(event){
    const location_id = event.currentTarget.getAttribute("data-location-id")
    jQuery(`#location-${location_id}-modal`).modal('hide')
    jQuery(this.locationsTarget).modal('show')
  }

}
