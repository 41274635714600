import { Controller } from "stimulus"
import Rails from "@rails/ujs";

export default class extends Controller {
  static targets = [
  "chart",
  "table",
  "showButton",
  "hideButton"
  ]

  connect(){
    var state = this.data.get("state");

    console.log(state);

    if(state != "false"){
      this.drawChart();
    }
  }

  hideChart(){
    $(this.chartTarget).slideUp(200);
    $(this.showButtonTarget).show();
    this.setCookie(false);
    setTimeout(function(){
      $.fn.matchHeight._update();
    }, 250)

  }

  showChart(){
    $(this.chartTarget).slideDown(200);
    $(this.showButtonTarget).hide();
    this.setCookie(true);
    this.drawChart();
    setTimeout(function(){
      $.fn.matchHeight._update();
    }, 250);
  }

  setCookie(state){
    // TRUE = SHOW
    // FALSE = HIDE

    Rails.ajax({
      type: "post",
      url: 'guests/chart_toggle/' + state
    })
  }

  drawChart(){
    var new_guests_vals = visitsData.map(value => value.a);
    var repeat_guests_vals = visitsData.map(value => value.b);
    var dates = visitsData.map(value => value.y);
    var stroke_width = 10;

    var new_guests = new_guests_vals.map(function(v, idx) {
      return {
        meta: dates[idx] + "," + "Repeat Guests: " + repeat_guests_vals[idx] + "," + "New Guests: " + v,
        value: v
      }
    })
    var repeat_guests = repeat_guests_vals.map(function(v, idx) {
      return {
        meta: dates[idx] + "," + "Repeat Guests: " + v + "," + "New Guests: " + new_guests_vals[idx],
        value: v
      }
    })

    var seq = 0,
      delays = 500 / dates.length,
      durations = 1;

    if (dates.length < 40) {
      stroke_width = 20;
    } else if (dates.length >= 40 && dates.length < 100) {
      stroke_width = 6;
    } else if (dates.length >= 100 && dates.length < 200) {
      stroke_width = 3;
    } else if (dates.length >= 200 && dates.length < 400) {
      stroke_width = 2;
    } else {
      stroke_width = 1;
    }


    var chart = new Chartist.Bar('#stacked-bar-chart', {
      labels: dates, //['New Guests', 'Repeat Guests'],
      series: [new_guests, repeat_guests]
    }, {
      classNames: {
        bar: 'ct-bar graph-bar',
      },
      stackBars: true,
      axisY: {
        labelInterpolationFnc: function(value) {
          return value % 1 === 0 ? value : null;
        }
      },
      axisX: {
        showGrid: false,
        labelInterpolationFnc: function(value, index) {
          if (dates.length > 500) {
            return index % 40 === 0 ? value : null;
          } else if (dates.length > 300) {
            return index % 20 === 0 ? value : null;
          } else if (dates.length > 71) {
            return index % 10 === 0 ? value : null;
          } else if (dates.length > 31) {
            return index % 5 === 0 ? value : null;
          } else if (dates.length > 13) {
            return index % 2 === 0 ? value : null;
          } else {
            return value;
          }
        }
      },
      plugins: [
        Chartist.plugins.tooltip({
          tooltipFnc: function(a, b) {
            var array = a.split(',');
            return "<span id='tooltip-yaxis' class='tooltip-header'>" + array[0] + "</span><hr><span id='new-guests'>" + array[1] + "</span><br><span id='repeat-guests'>" + array[2] + "</span>";
          },
          metaIsHTML: true,
        })
      ]
    });

    chart.on('created', function() {
        seq = 0;
      });

    chart.on('draw', function(data) {
      seq++;
      //console.log(data.seriesIndex)
      if (data.type === 'bar') {
        data.element.animate({
          opacity: {
            begin: seq * delays,
            dur: durations,
            from: 0,
            to: 1
          }
        });
      }

      if(data.type === 'bar' && data.seriesIndex === 1) {

        data.element.attr({
          style: 'stroke-width: ' + stroke_width + 'px; stroke: #E7EAEE;',
        });
      } else if (data.type === 'bar') {

        data.element.attr({
          style: 'stroke-width: ' + stroke_width + 'px; stroke: #1dabf2;',
        });

      }
    });


    $('#stacked-bar-chart').on('mouseleave', function(){
      let tooltip = $('.chartist-tooltip').first();
      tooltip.attr('style', 'opacity:0;');
      if(!tooltip.hasClass('hidden')){
        tooltip.addClass('hidden');
      }
    });

    $('#stacked-bar-chart').on('mousemove', function(event){
      try{
        var mouseX = event.pageX;
        var mouseY = (event.pageY - $(document).scrollTop());
        var tooltipX = $('.chartist-tooltip')[0].getBoundingClientRect()['x'];
        var above_xaxis = $('.ct-grids')[0].getBoundingClientRect()['bottom'] > (mouseY);
        var right_of_yaxis = $('.ct-bar')[0].getBoundingClientRect()['left'] < (mouseX + 5);
        let tooltip = $('.chartist-tooltip').first();
        if (!above_xaxis){
          tooltip.attr('style', 'opacity:0;');
          if(!tooltip.hasClass('hidden')){
            tooltip.addClass('hidden');
          }
        } else if (!right_of_yaxis) {
          tooltip.attr('style', 'opacity:0;');
          if(!tooltip.hasClass('hidden')){
            tooltip.addClass('hidden');
          }
        } else {
          tooltip.attr('style', 'opacity:1;');
          if(tooltip.hasClass('hidden')){
            tooltip.removeClass('hidden');
          }
        }
        tooltip.html("<span id='tooltip-yaxis' class='tooltip-header'></span><hr><span id='new-guests'></span><br><span id='repeat-guests'></span>");

        var found_bar
        $.each( $('.ct-bar'), function(index, value){
          if ((value.getBoundingClientRect()['x'] - Number(value.attributes['style'].value.split(' ')[1].split('px')[0]) ) >= mouseX) {
            return false;
          }
          found_bar = value;
        })
        if(found_bar){
          var data = found_bar.attributes['ct:meta'].value.split(',');
          $("#tooltip-yaxis").html(data[0]);
          $("#new-guests").html(data[1]);
          $("#repeat-guests").html(data[2]);
        }
      }catch(e){
        console.log(e)
      }


    })
  }

}
