import { Controller } from "stimulus"
import Rails from "@rails/ujs";

export default class extends Controller {
  static targets = [
    "email"
  ]

  create_lead(){
    let email = this.emailTarget.value
    if (this.validate_email(email)){
      $.ajax({
        type: "POST",
        url: `/leads?email=${email}`
      })
    }
  }

  validate_email(email){
    return email.match(
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/
    )
  }

}
