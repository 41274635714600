import { Controller } from "stimulus"
import Rails from "@rails/ujs";

export default class extends Controller {

  static targets = [
    "form"
  ]

  initialize() {

  }

  submit_form(event){
    this.formTarget.submit()
  }

  undo_import(event) {
    event.stopPropagation();
    event.preventDefault();
    let import_id = event.currentTarget.getAttribute("data-import-id");

    Weglot.translate({
      'words': [
        { "t": 1, "w": "Are you sure?" },
        { "t": 1, "w": "Undoing cannot be undone" },
        { "t": 1, "w": "Yes, undo this Import!" },
        { "t": 1, "w": "Cancel" }
      ],
      'languageTo': Weglot.getCurrentLang()
    }, function(data) {
      swal({
        title: data[0],
        text: data[1],
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: data[2],
        cancelButtonText: data[3],
        confirmButtonClass: 'btn btn-lg btn-danger waves-effect waves-light m-t-20',
        cancelButtonClass: 'btn btn-lg btn-transparent waves-effect waves-light m-l-10 m-t-20',
        buttonsStyling: false
      }).then(function() {
        document.querySelectorAll(".undo-import-action").forEach(function(elem){
          if (elem.getAttribute("data-import-id") == import_id) {
            elem.click();
          }
        });
      }, function(dismiss) {
        if (dismiss === 'cancel') {
          // Handle cancel action here if needed
        }
      });
    });
  }
}
