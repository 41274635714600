import { Controller } from "stimulus"
import Rails from "@rails/ujs";

export default class extends Controller {

  static targets = ['cardElement', 'cardErrors', 'form']

  connect() {
    var billingDetails = "error";

    $('form').validetta({
      showErrorMessages : true,
      display : 'bubble',
      errorTemplateClass : 'validetta-bubble',
      errorClass : 'validetta-error',
      validClass : 'validetta-valid',
      bubblePosition: 'bottom',
      bubbleGapLeft: 15,
      bubbleGapTop: 9,
      realTime : true,
      validators: {
        regExp: {
          validName: {
            pattern: /^[A-Za-z\s]+$/,
            errorMessage: "Please enter a valid name with only alphabets."
          }
        }
      },
      onValid : function(){
        billingDetails = "complete";
      },
      onError : function(){
        billingDetails = "error";
      }
    });

    console.log("Initialising Stripe...");
  	// INIT STRIPE WITH DATA KEY
  	var stripe = Stripe(this.data.get('key'));
    // SET PREFIX FOR IDs SO THERE ARE NOT MULTIPLE IN DOM
    var prefix = this.formTarget.getAttribute('data-prefix');

    // CREATE AN INSTANCE OF ELEMENTS
    var elements = stripe.elements();

    // STYLING FOR ELEMENTS
    var style = {
      base: {
        color: '#2c3e50',
        fontFamily: '"Circular Std", Helvetica, sans-serif',
        fontSmoothing: 'antialiased',
        fontWeight: '400',
        fontSize: '16px',
        '::placeholder': {
          color: '#9fa9b1'
        }
      },
      invalid: {
        color: '#fa755a',
        iconColor: '#fa755a'
      }
    };

    // CREATE AN INSTANCE OF ELEMENTS
    var card = elements.create('cardNumber', { style: style, showIcon: true });
    var cardExpiry = elements.create('cardExpiry', { style: style });
    var cardCvc = elements.create('cardCvc', { style: style, placeholder: '***' });

    // MOUNT ELEMENTS TO ID
    card.mount('#' + prefix + '-element');
    cardExpiry.mount('#card-expiry-element');
    cardCvc.mount('#card-cvc-element');

    // SET SOME REQUIRED VARS
    var customerToken = this.formTarget.getAttribute('data-customer-token');
    var cardButton = document.getElementById(prefix + '-submit');
    var cardState = "error";
    var form = this.formTarget;

    // MAKE SURE FORM IS COMPLETED
    card.on('change', function(event) {
      if (event.complete) {
        cardState = "complete"
      } else if (event.error) {
        cardState = "error"
      }
    });

    // WAIT FOR SUBMISSION
    form.addEventListener('submit', function(event) {
      // PREVENT NORMAL SUBMIT
      if (!$('#signup-card-submit').prop('disabled')) {$('#signup-card-submit').prop('disabled', true)}
      event.preventDefault();

      if (cardState == "error" || billingDetails == "error") {
        if ($('#signup-card-submit').prop('disabled')) {$('#signup-card-submit').prop('disabled', false)}
        toastr.error("Please finish entering your card details.");
        cardButton.blur();
      } else if (cardState == "complete" && billingDetails == "complete") {
        // Retrieve the additional field data from the form
        var firstName = document.getElementById('first-name').value;
        var lastName = document.getElementById('last-name').value;
        var cardholderName = firstName + ' ' + lastName;

        // CREATE STRIPE SETUP INTENT
        Rails.ajax({
          type: "post",
          url: '/stripe/intent/' + customerToken + '.json',
          success: function(data) {
            var response = data;

            // ONCE SETUP INTENT CREATED, ATTACH PAYMENT METHOD
            stripe.confirmCardSetup(
              response.client_secret, {
                payment_method: {
                  card: card,
                  billing_details: {
                    name: cardholderName,
                  },
                }
              }
            ).then(function(result) {
              if (result.error) {
                if ($('#signup-card-submit').prop('disabled')) {$('#signup-card-submit').prop('disabled', false)}
                // HANDLE ERRORS
                toastr.error(result.error.message);
                cardButton.blur();
              } else {
                // HANDLE SUCCESS
                document.getElementById("stripe_intent_id").setAttribute('value', result.setupIntent.id);
                form.submit();
              }
            });
          }
        });
      }
    });
  }
}
