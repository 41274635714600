import { Controller } from "stimulus";
import Rails from "@rails/ujs";

let swalRemoveConfirmed = false;

export default class extends Controller {
  static targets = [];

  initialize() {
    // Initialization logic if needed
  }

  async getTranslatedWords() {
    const result = await Weglot.translate({
      words: [
        { t: 1, w: "Are you sure?" },
        { t: 1, w: "Removing cannot be undone" },
        { t: 1, w: "Yes, remove it!" },
        { t: 1, w: "Cancel" },
      ],
      languageTo: Weglot.getCurrentLang(),
    });
    return result;
  }

  async deleteBlast(event) {
    if (!swalRemoveConfirmed) {
      event.stopPropagation();
      event.preventDefault();

      const id = event.currentTarget.getAttribute("data-id");

      let title = 'Are you sure?';
      let text  = 'Removing cannot be undone';
      let confirmButton = 'Yes, remove it!';
      let cancelButton  = 'Cancel';

      try {
        const translatedWords = await this.getTranslatedWords(title, text, confirmButton, cancelButton);

        if (translatedWords.length == 4) {
          [title, text, confirmButton, cancelButton] = translatedWords;
        }

        swal({
          title: title,
          text: text,
          type: "warning",
          showCancelButton: true,
          confirmButtonText: confirmButton,
          allowOutsideClick: true,
          cancelButtonText: cancelButton,
          confirmButtonClass: "btn btn-lg btn-danger waves-effect waves-light m-t-20",
          cancelButtonClass: "btn btn-lg btn-transparent waves-effect waves-light m-l-10 m-t-20",
          buttonsStyling: false,
        }).then(
          function () {
            swalRemoveConfirmed = true;
            $(".remove-trigger-" + id).trigger("click");
          },
          function (dismiss) {
            if (dismiss === "cancel") {
              // Handle cancel action here if needed
            }
          }
        );
      } catch (error) {}
    }
  }
}
