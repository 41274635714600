import { Controller } from "stimulus"

export default class extends Controller {

  static targets = ["url_segment_params", "url_filter_params", "url_search_params", "has_to_set"]

  initialize() {

    const url_segment_params = this.url_segment_paramsTarget.getAttribute("data-params") // $("#url_segment_params").attr('data-params');
    const url_filter_params = this.url_filter_paramsTarget.getAttribute("data-params") // $("#url_filter_params").attr('data-params');
    const url_search_params = this.url_search_paramsTarget.getAttribute("data-params") // $("#url_search_params").attr('data-params');
    const has_to_set = this.has_to_setTarget.getAttribute("data-params")
    const render_url = this.element.getAttribute("data-render-url")
    const cache_key = this.element.getAttribute("data-cache-key")

    if (url_segment_params != "false") {
      $.ajax({
        type: "GET",
        url: "/app/" + $("body").attr("data-account-id") + "/guests/render_segment_menu.js",
        data: {selected_segment_id: url_segment_params, render_url: render_url, cache_key: cache_key}
      });
    } else if (has_to_set != "false") {
      $.ajax({
        type: "GET",
        url: "/app/" + $("body").attr("data-account-id") + "/guests/render_segment_menu.js",
        data: {render_url: render_url, cache_key: cache_key},
        complete: function() {
          $.ajax({
            type: "GET",
            cache: false, // Appends _={timestamp} to the request query string
            url: render_url,
            data: {segment: "has_to_set", id: $("#blast_id").attr('data-params') },
            complete: function() {
              $.fn.matchHeight._update(); // TRIGGER MATCH HEIGHT FOR FOOTER POSITIONING
              feather.replace(); // UPDATE ICONS
              $('[data-toggle="tooltip"]').tooltip(); // INITIALIZE TOOLTIP
            }
          });
        }
      });
    } else if (url_filter_params != "false") {
      $.ajax({
        type: "GET",
        url: "/app/" + $("body").attr("data-account-id") + "/guests/render_segment_menu.js",
        data: {render_url: render_url, cache_key: cache_key, selected_segment_id: 'false'},
        complete: function() {
          let params_array = JSON.parse(url_filter_params.replaceAll('=>', ':'));
          $("#filter-summary").attr("data-filters", JSON.stringify(params_array));
          $("#filter-summary").html("<div data-controller='general--render-filters' data-render-url='" + render_url + "' data-target='general--render-filters.filters' data-filters='" + JSON.stringify(params_array) + "'></div>");
          $.ajax({
            type: "GET",
            url: render_url,
            data: {segment: "filter", params_array: params_array, cache_key: cache_key},
            complete: function() {
              $.fn.matchHeight._update(); // TRIGGER MATCH HEIGHT FOR FOOTER POSITIONING
              feather.replace(); // UPDATE ICONS
              $('[data-toggle="tooltip"]').tooltip(); // INITIALIZE TOOLTIP
            }
          });
        }
      });


    } else if (url_search_params != "false") {
      $.ajax({
        type: "GET",
        url: "/app/" + $("body").attr("data-account-id") + "/guests/render_segment_menu.js",
        data: {render_url: render_url, cache_key: cache_key},
        complete: function() {
          // IF @ PRESENT, SEARCH BY EMAIL OTHERWISE NAME
          if(url_search_params.includes("@")){
            var search_field = "email"
          } else {
            var search_field = "name"
          };

          // COMPILE SEARCH QUERIES
          let params_array = [{"query_type":"contains","filter_type":"string","search_field":search_field,"search_value":url_search_params}]
          let string = '[{"query_type":"contains","filter_type":"string","search_field":"' + search_field + '","search_value":"' + url_search_params + '"}]'

          // SET SEARCH QUERY
          $("#filter-summary").html("<div data-controller='general--render-filters' data-render-url='" + render_url + "' data-target='general--render-filters.filters' data-filters='" + string + "'></div>");

          // MAKE AJAX CALL TO LOAD TABLE
          $.ajax({
            type: "GET",
            url: "guests/render_guest_table.js",
            data: {segment: "filter", params_array: params_array, cache_key: cache_key},
            complete: function() {
              $.fn.matchHeight._update(); // TRIGGER MATCH HEIGHT FOR FOOTER POSITIONING
              feather.replace(); // UPDATE ICONS
              $('[data-toggle="tooltip"]').tooltip(); // INITIALIZE TOOLTIP
            }
          });

        }
      });
    } else {
      $.ajax({
        type: "GET",
        url: "/app/" + $("body").attr("data-account-id") + "/guests/render_segment_menu.js",
        data: {selected_segment_id: "first_default", render_url: render_url, cache_key: cache_key}
      })
    }

  }

}
