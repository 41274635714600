import { Controller } from "stimulus"
import Rails from "@rails/ujs";

export default class extends Controller {

  static targets = [
    "key"
  ]

  initialize() {
    console.log("hello")
  }

  rollkey() {

    swal({
      title: 'Are you sure?',
      text: "If you roll your API key you can't change it back",
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, roll my API key!',
      cancelButtonText: 'Cancel',
      confirmButtonClass: 'btn btn-lg btn-danger waves-effect waves-light m-t-20',
      cancelButtonClass: 'btn btn-lg btn-transparent waves-effect waves-light m-l-10 m-t-20',
      buttonsStyling: false
    }).then(function () {
      $.ajax({
        method: 'POST',
        url: 'roll_key',
        success: function(res) {
          $("#api_key").val(res["api_key"])
          toastr.success('Your API key has been updated.');
        }
      })
    }, function (dismiss) {
      if (dismiss === 'cancel') {
        // window.location.href = link;
      }
    })

  }

}
