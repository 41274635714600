import { Controller } from "stimulus"
import Rails from "@rails/ujs";

export default class extends Controller {
  static targets = [
    "page",
    "back",
    "sourceServer",
    "sourceUser",
    "sourcePass",
    "sourceToken",
    "targetServer",
    "targetUser",
    "targetPass"
  ]

  initialize() {
    // SET INITIAL PAGE AND COUNT
  	this.showPage(0);
  	this.pageCount = (this.pageTargets.length - 1);

    console.log("INITIALISED UNIFI INTEGRATION");
  }

  back() {
  	this.index = (this.index - 1);
    // CHECK IF BACK OR CANCEL
  	if(this.index == -1){
      // CLOSE MODAL
  		jQuery(this.element).modal("toggle");
  	} else {
      // GO TO PREVIOUS PAGE
	  	this.showPage(this.index);
  	};
  }

  showPage(index){
  	this.index = index

    // HIDE ALL PAGES AND SHOW THE ACTIVE ONE
  	jQuery(this.pageTargets).hide();
  	jQuery(this.pageTargets[index]).show();

    // UNLOCK NEXT BUTTON
    jQuery(this.nextTarget).prop("disabled", false);
  }

  grabSites(event){
    const [data, status, xhr] = event.detail;

    // CHECK IF API CALL WAS SUCCESFUL, OR RESPOND WITH ERROR
    if(data.length > 0){
      // REMOVE EXISTING DROPDOWN SITES TO AVOID DUPLICATES
      jQuery("select[name='unifi[site_id]'] option").remove();
      data.forEach(function (site, index) {
        // ADD SITES FROM API VALIDATION RESPONSE
        jQuery("select[name='unifi[site_id]']").prepend('<option value="' + site["name"] + '">' + site["desc"] + '</option>');
      });
      // TRANSFER VALUES FROM PREVIOUS STEPS FOR CREATE CALL
      jQuery(this.targetServerTarget).val(jQuery(this.sourceServerTarget).val());
      jQuery(this.targetUserTarget).val(jQuery(this.sourceUserTarget).val());

      const token = jQuery(this.sourceTokenTarget).val();
      var pass = jQuery(this.sourcePassTarget).val();

      if (token) {
        pass = pass + '|' + token;
      }

      jQuery(this.targetPassTarget).val(pass);
      // SWITCH PAGE
      this.showPage(1);
    } else {
      const errorMessage = data.error || 'While we could reach your controller, we could not authenticate. Please double check your UniFi admin details.';
      this.raiseError(errorMessage);

      jQuery('.btn-spinner').blur();
    }
  }

  createIntegration(event){
    const [data, status, xhr] = event.detail;
    if(data.error  == undefined){
      this.completed();
    }else{
     const errorMessage = data.error || 'While we could reach your controller, we could setup your site. Please double check your UniFi guest policy details.';
     this.raiseError(errorMessage); 
    }
  }

  updateToken(event) {
    const [data, status, xhr] = event.detail;

    this.updated(data.message);
  }

  ajaxError(){
    this.raiseError("We could not connect to your UniFi controller, please test your URL and make sure it is reachable from outside of your network.");
  }

  raiseError(message = "We could not connect to your UniFi controller, please test your URL and make sure it is reachable from outside of your network."){
    toastr.error(message);
    jQuery('.btn-spinner').blur();
  }

  tokenUpdateError(event) {
    const [data, status, xhr] = event.detail;

    this.raiseError(data.error);
  }

  completed(){
    // CLOSE MODAL
    jQuery(this.element).modal("hide");

    // DISPLAY SWEET ALERT NOTIFICATION
    swal({
      title: 'Location activated',
      text: "Your WiFi login is now active on your UniFi network",
      type: 'success',
      confirmButtonText: 'Continue',
      confirmButtonClass: 'btn btn-primary waves-effect waves-light',
      buttonsStyling: false
    }).then(function () {
      Turbolinks.visit(window.location.href.substr(0, window.location.href.indexOf('#')));
    });
  }

  updated(message){
    jQuery(this.element).modal("hide");

    swal({
      title: message,
      text: "Your WiFi login is now active on your UniFi network",
      type: 'success',
      confirmButtonText: 'Continue',
      confirmButtonClass: 'btn btn-primary waves-effect waves-light',
      buttonsStyling: false
    }).then(function () {
      Turbolinks.visit(window.location.href.substr(0, window.location.href.indexOf('#')));
    });
  }
}
