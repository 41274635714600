import { Controller } from "stimulus"
import Rails from "@rails/ujs";

export default class extends Controller {

  static targets = [
    "locations",
    "months"
  ]



  changeDate(){

    const locations = this.locationsTarget.getAttribute("data-value")

    const months = event.currentTarget.getAttribute("data-months");
    const option = event.currentTarget.getAttribute("data-option");
    $('#timeframe_current').text(option);
    $('#render_block').html('<div class="container"><div class="spinner-border text-center text-primary m-t-100" role="status"><span class="sr-only">Loading...</span></div></div>');

    this.monthsTarget.setAttribute("data-value", months)


    $.ajax({
				url: this.element.getAttribute("data-url"),
				type: 'GET',
				cache: false,
				data: {locations: locations, months: months},
				success: function(res) {

          if (months == "1") {
            $(".text_span").html("30 days");
          } else {
            $(".text_span").html(months + " months");
          }

					$.fn.matchHeight._update();

					$(function(){
						let height = $('.actual-content').outerHeight();
						$('.human-bar').height(height);
						$( ".human-bar .widget-user:gt(" + Math.floor((height / 105) - 1) + ")").remove();
					});

					$('[data-plugin="knob"]').each(function(idx, obj) {
						 $(this).knob({
               'format' : function(value) {
                 return value + '%';
               }
             });
					});

				}
		}); // render dashboard partial

  }

  changeLocation(){

    const months = this.monthsTarget.getAttribute("data-value")

    const locations = event.currentTarget.getAttribute("data-locations");
    const option = event.currentTarget.getAttribute("data-option");
    $('#location_current').attr('data-active', locations);
    $('#location_current').text(option);
    $('#render_block').html('<div class="container"><div class="spinner-border text-center text-primary m-t-100" role="status"><span class="sr-only">Loading...</span></div></div>');

    this.locationsTarget.setAttribute("data-value", locations)

    $.ajax({
				url: this.element.getAttribute("data-url"),
				type: 'GET',
				cache: false,
				data: {locations: locations, months: months},
				success: function(res) {

          if (months == "1") {
            $(".text_span").html("30 days");
          } else {
            $(".text_span").html(months + " months");
          }

					$.fn.matchHeight._update();

					$(function(){
						let height = $('.actual-content').outerHeight();
						$('.human-bar').height(height);
						$( ".human-bar .widget-user:gt(" + Math.floor((height / 105) - 1) + ")").remove();
					});

					$('[data-plugin="knob"]').each(function(idx, obj) {
            $(this).knob({
              'format' : function(value) {
                return value + '%';
              }
            });
					});
				}
		}); // render dashboard partial

  }

}
