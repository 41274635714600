import { Controller } from "stimulus"
import Rails from "@rails/ujs";

export default class extends Controller {
  static targets = [

  ]

  initialize(){

  }

  formTrigger(event){
    const refUrl = event.currentTarget.getAttribute('data-ref-url')
    jQuery("form.edit_blast #ref").val(refUrl)
    jQuery('form.edit_blast').submit();
  }

  step3FomTrigger(event){
    const refUrl = event.currentTarget.getAttribute('data-ref-url')
    jQuery("form.edit_blast #ref").val(refUrl)
    window.bee.save()
  }

  setBlastTigger(event){
    let blast_id = $("#blast_id").attr("data-params")
    let params_array = JSON.parse( $("#filter-summary").attr("data-filters") )
    $.ajax({
      type: "POST",
      url: "/app/" + $("body").attr("data-account-id") + "/blast/update_trigger",
      data: {id: blast_id, filters: params_array},
    });
    this.formTrigger(event)
    //$('form.edit_interaction #ref').val($(this).data('ref-url'));
    //$('form.edit_interaction').submit();
  }


}
