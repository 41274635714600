import { Controller } from "stimulus"

export default class extends Controller {

  static targets = [
    "new_button_label",
    "new_button_text",
    "button_container"
  ]

  

}
