import { Controller } from "stimulus"
import Rails from "@rails/ujs";

export default class extends Controller {
  static targets = [
    'skipButton',
  ]

  connect(){
    console.log('User Onboarding Dashboard...');

    this.setStepCount()
  }

  setStepCount() {
    const completedSteps = document.querySelectorAll('.step-completed').length;
    const progressWidth = (completedSteps / 8) * 100;
    document.getElementById('progressBar').style.width = `${progressWidth}%`;

    if (completedSteps == 8) {
      const tadaIcon = document.createElement('img');
      tadaIcon.src = '/assets/mission_control/humans/tada-icon.svg';

      const collapseIcon = document.getElementById("collapseIcon");
      collapseIcon.setAttribute("aria-expanded", "false");

      document.getElementById('stepCount').innerHTML = "Completed";
      document.getElementById('tadaIcon').appendChild(tadaIcon);
    }
    else {
      document.getElementById('stepCount').innerHTML = `${completedSteps} / 8`;
      document.getElementById('collapseOne').classList.add('in');
    }
  }

  skipStep(event) {
    event.preventDefault();

    const eventText = this.data.get('event-text');
    const event_feature = this.data.get('event-feature');

    const skipUrl = this.data.get('skip-path');
    const skipButtonTarget = this.skipButtonTarget;

    $.ajax({
      method: 'POST',
      url: skipUrl,
      data: { event_type: 'button', event_text: eventText, feature: event_feature },
      success: function() {
        skipButtonTarget.classList.add('hidden');

        const parentLi = skipButtonTarget.closest('li');
        parentLi.classList.add('completed-check');

        const completedDiv = document.getElementById(eventText);
        completedDiv.innerHTML = "";
        completedDiv.classList.add('step-completed');

        const completedText = document.createElement('span');
        completedText.textContent = 'Completed';
        const completedImage = document.createElement('img');
        completedImage.src = '/assets/mission_control/humans/completed-icon.svg';

        completedDiv.appendChild(completedText);
        completedDiv.appendChild(completedImage);

        const completedSteps = document.querySelectorAll('.step-completed').length;
        const progressWidth = (completedSteps / 8) * 100;
        document.getElementById('progressBar').style.width = `${progressWidth}%`;

        if (completedSteps == 8) {
          const tadaIcon = document.createElement('img');
          tadaIcon.src = '/assets/mission_control/humans/tada-icon.svg';

          document.getElementById('stepCount').innerHTML = "Completed";
          document.getElementById('tadaIcon').appendChild(tadaIcon);
        }
        else{
          document.getElementById('stepCount').innerHTML = `${completedSteps} / 8`;
        }
      },
      error: function() {
        toastr.error("Something happened! Please try again later.");
      }
    });
  }
}
