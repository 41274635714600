import { Controller } from "stimulus"

export default class extends Controller {

  static targets = [
    "field",
    "template_id"
  ]

  save() {
    const field = this.fieldTarget.value
    const template_id = this.template_idTarget.value
    let data = {field: field, template_id: template_id}

    if (document.getElementsByName(field + "-french").length > 0) {
      const french_translation = document.getElementsByName(field + "-french")[0].value
      data["french_translation"] = french_translation
    }
    if (document.getElementsByName(field + "-spanish").length > 0) {
      const spanish_translation = document.getElementsByName(field + "-spanish")[0].value
      data["spanish_translation"] = spanish_translation
    }
    if (document.getElementsByName(field + "-german").length > 0) {
      const german_translation = document.getElementsByName(field + "-german")[0].value
      data["german_translation"] = german_translation
    }
    if (document.getElementsByName(field + "-italian").length > 0) {
      const italian_translation = document.getElementsByName(field + "-italian")[0].value
      data["italian_translation"] = italian_translation
    }
    if (document.getElementsByName(field + "-portuguese").length > 0) {
      const portuguese_translation = document.getElementsByName(field + "-portuguese")[0].value
      data["portuguese_translation"] = portuguese_translation
    }
    if (document.getElementsByName(field + "-turkish").length > 0) {
      const turkish_translation = document.getElementsByName(field + "-turkish")[0].value
      data["turkish_translation"] = turkish_translation
    }
    //
    if (document.getElementsByName(field + "-bulgarian").length > 0) {
      const bulgarian_translation = document.getElementsByName(field + "-bulgarian")[0].value
      data["bulgarian_translation"] = bulgarian_translation
    }
    if (document.getElementsByName(field + "-dutch").length > 0) {
      const dutch_translation = document.getElementsByName(field + "-dutch")[0].value
      data["dutch_translation"] = dutch_translation
    }
    if (document.getElementsByName(field + "-chinese").length > 0) {
      const chinese_translation = document.getElementsByName(field + "-chinese")[0].value
      data["chinese_translation"] = chinese_translation
    }
    if (document.getElementsByName(field + "-mexican").length > 0) {
      const mexican_translation = document.getElementsByName(field + "-mexican")[0].value
      data["mexican_translation"] = mexican_translation
    }
    if (document.getElementsByName(field + "-finnish").length > 0) {
      const finnish_translation = document.getElementsByName(field + "-finnish")[0].value
      data["finnish_translation"] = finnish_translation
    }
    if (document.getElementsByName(field + "-hungarian").length > 0) {
      const hungarian_translation = document.getElementsByName(field + "-hungarian")[0].value
      data["hungarian_translation"] = hungarian_translation
    }
    if (document.getElementsByName(field + "-japanese").length > 0) {
      const japanese_translation = document.getElementsByName(field + "-japanese")[0].value
      data["japanese_translation"] = japanese_translation
    }
    if (document.getElementsByName(field + "-korean").length > 0) {
      const korean_translation = document.getElementsByName(field + "-korean")[0].value
      data["korean_translation"] = korean_translation
    }
    if (document.getElementsByName(field + "-romanian").length > 0) {
      const romanian_translation = document.getElementsByName(field + "-romanian")[0].value
      data["romanian_translation"] = romanian_translation
    }
    if (document.getElementsByName(field + "-norwegian").length > 0) {
      const norwegian_translation = document.getElementsByName(field + "-norwegian")[0].value
      data["norwegian_translation"] = norwegian_translation
    }
    if (document.getElementsByName(field + "-polish").length > 0) {
      const polish_translation = document.getElementsByName(field + "-polish")[0].value
      data["polish_translation"] = polish_translation
    }
    if (document.getElementsByName(field + "-russian").length > 0) {
      const russian_translation = document.getElementsByName(field + "-russian")[0].value
      data["russian_translation"] = russian_translation
    }
    if (document.getElementsByName(field + "-swedish").length > 0) {
      const swedish_translation = document.getElementsByName(field + "-swedish")[0].value
      data["swedish_translation"] = swedish_translation
    }
    if (document.getElementsByName(field + "-ukranian").length > 0) {
      const ukranian_translation = document.getElementsByName(field + "-ukranian")[0].value
      data["ukrainian_translation"] = ukranian_translation
    }
    if (document.getElementsByName(field + "-slovak").length > 0) {
      const slovak_translation = document.getElementsByName(field + "-slovak")[0].value
      data["slovak_translation"] = slovak_translation
    }

    jQuery('#language-manager-' + field).modal('toggle')
    console.log(data)
    $.ajax({
      type: "POST",
      url: "save_translations",
      cache: false,
      data: data,
      success: function() {
        toastr.success('Your translations have been saved.')
      },
      failure: function() {
        toastr.danger("Your translations could not be saved. Please check and try again.")
      }
    })

  }

  async getTranslatedWords(title, text, confirmButton, cancelButton) {
    const result = await Weglot.translate({
      words: [
        { t: 1, w: title },
        { t: 1, w: text },
        { t: 1, w: confirmButton },
        { t: 1, w: cancelButton },
      ],
      languageTo: Weglot.getCurrentLang(),
    });
    return result;
  }

  new_translation(event){
    const locale = event.currentTarget.value
    const flag_code = this.flag_code(locale)
    //const field = event.currentTarget.getAttribute("data-field")
    $.ajax({
      type: "POST",
      url: "assign_translation",
      cache: false,
      data: {locale: locale},
      success: function(res){
        let title_html = '<div class="col-md-6 translation-' + locale + '"><div class="form-group text-left m-b-25"><label for="title-' + res["translation_downcase"] + '"><span class="flag-icon flag-icon-' + flag_code + '"></span>' + res["translation"] + '</label><span class="remove-translation" data-controller="setups--translations" data-action="click->setups--translations#remove_translation" data-locale="' + locale + '"><i data-feather="x" width="14" height="14"></i></span><input type="text" name="title-' + res["translation_downcase"] + '" id="title-' + res["translation_downcase"] + '" class="form-control"></div></div>'
        $("#title-translations").append(title_html)

        let description_html = '<div class="col-md-6 translation-' + locale + '"><div class="form-group text-left m-b-25"><label for="description-' + res["translation_downcase"] + '"><span class="flag-icon flag-icon-' + flag_code + '"></span>' + res["translation"] + '</label><span class="remove-translation" data-action="click->setups--translations#remove_translation" data-locale="' + locale + '"><i data-feather="x" width="14" height="14"></i></span><div class="tooltip-input"><div id="' + locale + '-snow-editor" class="snow-editor" data-controller="setups--translations" data-action="keyup->setups--translations#updateQuill" data-locale="' + locale + '"></div><textarea type="text" name="description-' + res["translation_downcase"] + '" id="description-' + res["locale"] + '" value="" class="form-control" style="display:none;"></textarea></div></div></div>'
        $("#description-translations").append(description_html)

        jQuery('option[value="' + locale + '"]').remove()
        feather.replace()
        new Quill( $("#" + locale + "-snow-editor")[0], {
          theme: "snow",
          modules: {
            toolbar: [
              ["bold", "italic", "underline"],
              ["link"],
              // [{ 'align': [] }]
            ]
          },
          bounds: $("#" + locale + "-snow-editor").first().parent()[0]
        })
      }
    })
  }

  flag_code(locale){
    if ( ['zh-CN', 'ja', 'ko', 'uk'].includes(locale) ) {
      if (locale == 'zh-CN'){
        return "cn"
      }
      if (locale == 'ja'){
        return "jp"
      }
      if (locale == 'ko'){
        return "kr"
      }
      if (locale == 'uk'){
        return "ua"
      }
    } else {
      return locale
    }
  }

  async remove_translation(event){
    const locale = event.currentTarget.getAttribute("data-locale")

    let title = 'Are you sure?';
    let text  = 'Your are about to remove this language';
    let confirmButton = 'Yes, remove language!';
    let cancelButton  = 'Cancel';

    try {
        const translatedWords = await this.getTranslatedWords(title, text, confirmButton, cancelButton);

        if (translatedWords.length == 4) {
          [title, text, confirmButton, cancelButton] = translatedWords;
        }

      swal({
        title: title,
        text: text,
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: confirmButton,
        cancelButtonText: cancelButton,
        confirmButtonClass: 'btn btn-lg btn-danger waves-effect waves-light m-t-20',
        cancelButtonClass: 'btn btn-lg btn-transparent waves-effect waves-light m-l-10 m-t-20',
        buttonsStyling: false
      }).then(function () {

        $.ajax({
          type: "DELETE",
          url: "remove_translation",
          cache: false,
          data: {locale: locale},
          success: function(res){
            $(".translation-" + locale).remove()
            jQuery('.add-translation').append('<option value="' + locale + '">' + res["translation"] + '</option>')
          }
        })

      }, function (dismiss) {
        if (dismiss === 'cancel') {
          // window.location.href = link;
        }
      })
    } catch (e) {}
  }

  update_default(event){
    const field = event.currentTarget.getAttribute("data-field")
    if (field == "title"){
      const value = event.currentTarget.value
      $("#title-default").val(value)
      $("#mainframe, #facebookframe, #twitterframe, #mainframe-default").contents().find("h1").text(value)
    } else {
      const value = $("#gb-snow-editor .ql-editor").html()
      $("#description-default").html(value)
      $("#snow-editor .ql-editor").html(value)
    }
  }

  updateQuill(event){
    const locale = event.currentTarget.getAttribute("data-locale")
    const value = $("#" + locale + "-snow-editor .ql-editor").html()
    $("#description-" + locale).val(value)
  }

}
