import { Controller } from "stimulus"
import Rails from "@rails/ujs";

export default class extends Controller {
  static targets = [
    "url",
    "locations",
    "time"
  ]

  initialize(){
    //console.log("hello world")
  }

  timeSelector(event){
    document.getElementById('render_block').innerHTML = '<div class="spinner-border text-center text-primary m-t-100" role="status"><span class="sr-only">Loading...</span></div>'
    const params = {}
    if (this.locationsTarget.value == "true"){
      params["locations"] = document.getElementById('location_current').getAttribute('data-active')
    }
    const months = event.currentTarget.getAttribute('data-months')
    const option = event.currentTarget.getAttribute('data-option')
    document.getElementById('timeframe_current').setAttribute('data-active', months)
    document.getElementById('timeframe_current').textContent = option
    params["months"] = months
    $.ajax({
      url: this.urlTarget.value,
      cache: false,
      type: 'GET',
      data: params,
    });

  }

  locationSelector(event){
    document.getElementById('render_block').innerHTML = '<div class="spinner-border text-center text-primary m-t-100" role="status"><span class="sr-only">Loading...</span></div>'
    const params = {}
    if (this.timeTarget.value == "true"){
      params["months"] = document.getElementById('timeframe_current').getAttribute('data-active')
    }
    const locations = event.currentTarget.getAttribute('data-locations')
    const option = event.currentTarget.getAttribute('data-option')
    document.getElementById('location_current').setAttribute('data-active', locations)
    document.getElementById('location_current').textContent = option
    params["locations"] = locations
    $.ajax({
      url: this.urlTarget.value,
      cache: false,
      type: 'GET',
      data: params,
    });
  }


}
