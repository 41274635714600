import { Controller } from "stimulus"

export default class extends Controller {

  static targets = [
    "setup_button",
    "preview_button"
  ]

  initialize(){
    this.preview_visable = false
  }

  toggle_preview(){
    if(this.preview_visable){
      this.preview_visable = false
      document.querySelector(".settings").style.display = "block"
      document.querySelector(".visual").style.display = "none"
      this.setup_buttonTarget.style.display = "none"
      this.preview_buttonTarget.style.display = "block"
    } else {
      this.preview_visable = true
      document.querySelector(".settings").style.display = "none"
      document.querySelector(".visual").style.display = "block"
      this.setup_buttonTarget.style.display = "block"
      this.preview_buttonTarget.style.display = "none"
    }
  }

  next(){
    document.querySelector(".form-trigger").click()
  }

}
