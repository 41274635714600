import { Controller } from "stimulus"

export default class extends Controller {

  static targets = ["search_field", "filter_type"]

  initialize() {
    //this.initialize_reviews();
    // document.getElementById("filter-summary").setAttribute("data-filters", "[]")
  }

  addFilter() {

    event.preventDefault()
    $(".filter-dropdown-menu").hide();
    event.stopPropagation()
    $("#add-filter-menu").show();
    $('.filter-dropdown-menu').click(function(e) {
      e.stopPropagation()
    })
    function closeDropdowns() {
      $(".filter-dropdown-menu").hide();
      $(document.body).off('click');
    }
    $(document.body).click(closeDropdowns);
  }

  filterField(){
    $(".filter-dropdown-menu").hide();

    let search_field = event.currentTarget.getAttribute("data-search-field")
    let filter_type = event.currentTarget.getAttribute("data-filter-type")

    this.search_fieldTarget.setAttribute("data-value", search_field)
    this.filter_typeTarget.setAttribute("data-value", filter_type)

    if ( filter_type == "string") {
      $('#filter-string').show();
    } else if ( filter_type == "rating") {
      $('#filter-rating').show();
    } else if ( filter_type == "location") {
      $('#filter-location').show();
    } else if ( filter_type == "date") {
      $('#filter-date').show();
    } else if ( filter_type == "platform" ) {
      $('#filter-provider').show();
    }

  }

  queryType(){
    let filter_type = this.filter_typeTarget.getAttribute("data-value")
    let search_field = this.search_fieldTarget.getAttribute("data-value")
    //let render_url = this.element.getAttribute("data-render-url")
    $(".apply-filter a").css('cursor', 'not-allowed');
    $("#search_value").remove();
    if ( ["string", "integer", "date", "birthday"].includes(filter_type) && !["is unknown", "has any value"].includes( $(".dropdown-item [type=radio]:checked").val() ) ) {
      let input = document.createElement('input')
      let type = document.createAttribute('type')
      if (filter_type == "string") {
        type.value = "text"
      } else {
        type.value = "number"
      }
      input.setAttributeNode(type)
      let id = document.createAttribute('id')
      id.value = "search_value"
      input.setAttributeNode(id)
      let class_elem = document.createAttribute('class')
      class_elem.value = 'form-control'
      input.setAttributeNode(class_elem)
      let controller_attribute = document.createAttribute('data-controller')
      controller_attribute.value = "reviews--filter-reviews"
      input.setAttributeNode(controller_attribute)
      //let action_attribute = document.createAttribute('data-action')
      //action_attribute.value = "keyup->reviews--filter-reviews#enterFilter"
      //input.setAttributeNode(action_attribute)
      let filter_type_node = document.createAttribute('data-filter-type')
      filter_type_node.value = filter_type
      input.setAttributeNode(filter_type_node)
      let search_field_node = document.createAttribute('data-search-field')
      search_field_node.value = search_field
      input.setAttributeNode(search_field_node)
      //let data_render_url = document.createAttribute("data-render-url")
      //data_render_url.value = render_url
      //input.setAttributeNode(data_render_url)
      event.currentTarget.closest("label").appendChild(input)
    }
    if ($(".dropdown-item [type=radio]:checked").length > 0) {
      $(".apply-filter a").css('cursor', 'pointer');
      //$(".apply-filter").click(applyFilter);
    }
  }

  initialize_reviews(){
    //$("#render_block").html('<div class="spinner-border text-center text-primary m-t-100 m-b-100" role="status"><span class="sr-only">Loading...</span></div>');

  }

  valid_dropdown_selection(){
    if (document.querySelectorAll(".dropdown-item input[type='radio']:checked").length > 0){
      return true
    } else {
      return false
    }
  }

  applyFilter() {
    if (this.valid_dropdown_selection()){
      $("body").addClass("loading-page");
      let filter_type = this.filter_typeTarget.getAttribute("data-value")
      let search_field = this.search_fieldTarget.getAttribute("data-value")
      let params_array = JSON.parse( $("#filter-summary").attr("data-filters") )
      let search_value
      let query_type
      let render_url = this.element.getAttribute("data-render-url")

      $(".filter-dropdown-menu").hide();
      $("#render_block").html('<div class="spinner-border text-center text-primary m-t-100 m-b-100" role="status"><span class="sr-only">Loading...</span></div>');
      if ( filter_type == "string") {
        query_type = $("#filter-string [type=radio]:checked").val();
        if ( !["is unknown", "has any value"].includes( $(".dropdown-item [type=radio]:checked").val() ) ) {
          search_value = $("#search_value").val();
        } else {
          search_value = query_type;
        }
      } else if ( filter_type == "location") {
        query_type = $("#filter-location [type=radio]:checked").val();
        search_value = query_type;
      } else if ( filter_type == "platform" ) {
        query_type = $("#filter-provider [type=radio]:checked").val();
        search_value = query_type;
      } else if ( filter_type == "rating" ) {
        query_type = $("#filter-rating [type=radio]:checked").val();
        search_value = query_type;
      } else {
        query_type = $("#filter-date [type=radio]:checked").val();
        search_value = $("#search_value").val();
      }

      let search_params = {filter_type:filter_type, search_field:search_field, query_type:query_type, search_value:search_value};
      params_array.push(search_params);
      $("#filter-summary").attr("data-filters", JSON.stringify(params_array));
      $("#filter-summary").html("<div data-controller='general--render-filters' data-target-controller='reviews--filter-reviews' data-render-url='" + render_url + "' data-target='general--render-filters.filters' data-filters='" + JSON.stringify(params_array) + "'></div>");

      $.ajax({
        type: 'GET',
        url: 'reviews/render_summary',
        cache: false,
        data: {filters: params_array},
        complete: function() {
          $("body").removeClass("loading-page"); // REMOVE LOADER
          $('[data-toggle="tooltip"]').tooltip(); // INITIALIZE TOOLTIP
          $.fn.matchHeight._update(); // MATCH HEIGHT
          $('#review-cont').isotope({ // LAY OUT GRID
            filter: '*',
            animationOptions: {
                duration: 750,
                easing: 'linear',
                queue: false
            }
          });
        }
      })
      document.querySelector(".dropdown-item input[type='radio']:checked").checked = false
      $("#search_value").remove();
    }
  };

  removeFilter(){
    $("body").addClass("loading-page");
    $("#render_block").html('<div class="spinner-border text-center text-primary m-t-100 m-b-100" role="status"><span class="sr-only">Loading...</span></div>');
    let index = Number(event.currentTarget.getAttribute("data-array-position"));
    let params_array = JSON.parse( $("#filter-summary").attr("data-filters") )
    let render_url = event.currentTarget.getAttribute("data-render-url")
    console.log(params_array)
    params_array.splice(index, 1);
    console.log(params_array)
    $("#filter-summary").attr("data-filters", JSON.stringify(params_array));
    $("#filter-summary").html("<div data-controller='general--render-filters' data-target-controller='reviews--filter-reviews' data-render-url='" + render_url + "' data-target='general--render-filters.filters' data-filters='" + JSON.stringify(params_array) + "'></div>");
    $.ajax({
      type: "GET",
      url: render_url,
      data: {filters: params_array},
      complete: function() {
        $.fn.matchHeight._update(); // TRIGGER MATCH HEIGHT FOR FOOTER POSITIONING
        feather.replace(); // UPDATE ICONS
        $('[data-toggle="tooltip"]').tooltip(); // INITIALIZE TOOLTIP
        $("body").removeClass("loading-page");
        $('#review-cont').isotope({ // LAY OUT GRID
          filter: '*',
          animationOptions: {
              duration: 750,
              easing: 'linear',
              queue: false
          }
        });
      }
    });
  }

}
