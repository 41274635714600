import { Controller } from "stimulus"
import Rails from "@rails/ujs";

export default class extends Controller {

  static targets = [
	  "days",
	  "hours",
	  "mins",
	  "secs"
  ]

  initialize() {
  	var eventDate = jQuery('.article.main').data('event-date');
  	var daysEl = this.daysTarget;
  	var hoursEl = this.hoursTarget;
  	var minsEl = this.minsTarget;
  	var secsEl = this.secsTarget;

  	eventCountdown();
	  function eventCountdown(){
	    // Set the date we're counting down to
	    var countDownDate = new Date(eventDate).getTime();

	    // Update the count down every 1 second
	    var x = setInterval(function() {

	      // Get today's date and time
	      var now = new Date().getTime();

	      // Find the distance between now and the count down date
	      var distance = countDownDate - now;

	      // Time calculations for days, hours, minutes and seconds
	      var days = Math.floor(distance / (1000 * 60 * 60 * 24));
	      var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
	      var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
	      var seconds = Math.floor((distance % (1000 * 60)) / 1000);

	      jQuery(daysEl).text(days);
	      jQuery(hoursEl).text(hours);
	      jQuery(minsEl).text(minutes);
	      jQuery(secsEl).text(seconds);
	    }, 1000);
	  };
  }

}