require("@rails/ujs").start()
require("channels")
//import "controllers"
//import Rails from 'rails-ujs';
//Rails.start()

// src/application.js
import { Application } from "stimulus"
import { definitionsFromContext } from "stimulus/webpack-helpers"
const application = Application.start()

const mission_control = require.context("./controllers/mission_control", true, /\.js$/)
application.load(definitionsFromContext(mission_control))

const website = require.context("./controllers/website", true, /\.js$/)
application.load(definitionsFromContext(website))

const town_square = require.context("./controllers/town_square", true, /\.js$/)
application.load(definitionsFromContext(town_square))


// BELOW IS OMITTED AS IT WAS USED TO CALL THE CONTROLLERS FROM WITHIN THE ENGINES WHERE WE NO LONGER STORE THEM.
//const application = Application.start()
// THE BELOW IS OMMITED AS CONTROLLERS FOLDER NO LONGER EXISTS AT TOP-LEVEL
//application.load(definitionsFromContext(context))

// MISSION CONTROL CONTROLLERS
//const mission_control = require.context("mission_control/app/javascript/packs/controllers", true, /\.js$/)
//application.load(definitionsFromContext(mission_control))

// WEBSITE CONTROLLERS
//const website = require.context("website/app/javascript/packs/controllers", true, /\.js$/)
//application.load(definitionsFromContext(website))
