import { Controller } from "stimulus"
import Rails from "@rails/ujs";

export default class extends Controller {
  static targets = [
    "window",
    "trigger"
  ]

  initialize() {
    // SET GLOBAL VARS
    
    this.index = false
    this.activeWindow = false 
  }

  trigger(){
    // TRIGGERS WHEN A NAV PARENT IS CLICKED

    this.index = event.currentTarget.getAttribute("data-index");
    this.showActiveWindow();
  }

  showActiveWindow(){
    // SHOWS THE CORRECT DROPDOWN WINDOW BASED ON INDEX

    this.windowTargets.forEach((element, index) => {
      if(index == this.index){
        $(element).show();
        $(element).addClass("active");
        this.activeWindow = element;
      } else {
        $(element).hide();
        $(element).removeClass("active");
      }
    })

    this.positionActiveWindow();
  }

  positionActiveWindow(){
    // POSITIONS THE ACTIVE MODAL CORRECTLY

    var halfPortWidth = (window.innerWidth / 2);
    var halfWindowWidth = ($(this.activeWindow).width() / 2);
    var leftOffset = (halfPortWidth - halfWindowWidth);

    $(this.activeWindow).css({ left: leftOffset });

    this.positionPointer();
  }

  positionPointer(){
    // POSITION TRIANGULAR POINTER RELATIVE TO TRIGGER

    var windowOffset = $(this.activeWindow).offset().left;
    var triggerOffset = $("[data-index=" + this.index + "]").offset().left;
    var triggerWidth = $("[data-index=" + this.index + "]").width();
    var pointerWidth = $("[data-index=" + this.index + "] .pointer").width();
    var pointerOffset = (triggerOffset - windowOffset) + (triggerWidth / 2) + (pointerWidth);

    $(this.activeWindow).find(".pointer").css({ left: pointerOffset });
  }

  mouseLeaveTrigger(){
    // DETECT WHEN WINDOW LEAVES ACTIVE WINDOW

    if($(".window.active:hover").length == 0){
      this.closeActiveWindow();
    }
  }

  closeActiveWindow(){
    // CLOSE THE ACTIVE WINDOW

    $(this.activeWindow).hide();
  }

  matchHeight(){
    // MATCH HEIGHT OF MAIN SECTION AND BLUE BOX

    var boxes = $(this.activeWindow).find(".match-height");
    var heighestHeight = 0;

    if(boxes.length != 0){
      $(boxes).each(function(){
        if($(this).height() > heighestHeight){
          heighestHeight = $(this).height();
        }
      });
    }

    $(boxes).css("height", heighestHeight);
  }

}