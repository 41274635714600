import { Controller } from "stimulus"
import Rails from "@rails/ujs";

export default class extends Controller {

  static targets = [
    "delete_link"
  ]

  connect(){

  }


  remove_form(event){
    event.stopPropagation()
    event.preventDefault()
    let controller = this
    const id = event.currentTarget.getAttribute("data-id")

    Weglot.translate({
      'words': [
        { "t": 1, "w": "Are you sure?" },
        { "t": 1, "w": "Removing cannot be undone" },
        { "t": 1, "w": "Yes, remove this embeddable form!" },
        { "t": 1, "w": "Cancel" }
      ],
      'languageTo': Weglot.getCurrentLang()
    }, function(data) {
      const title = data[0];
      const text = data[1];
      const confirmButton = data[2];
      const cancelButton = data[3];

      swal({
        title: title,
        text: text,
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: confirmButton,
        cancelButtonText: cancelButton,
        confirmButtonClass: 'btn btn-lg btn-danger waves-effect waves-light m-t-20',
        cancelButtonClass: 'btn btn-lg btn-transparent waves-effect waves-light m-l-10 m-t-20',
        buttonsStyling: false
      }).then(function () {
          controller.delete_linkTarget.click()
      }, function (dismiss) {
          if (dismiss === 'cancel') {
            // window.location.href = link;
          }
      });
    });
  }
}
