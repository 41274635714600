import { Controller } from "stimulus"
import Rails from "@rails/ujs";

export default class extends Controller {
  static targets = [
    "menu",
    "insights_menu",
    "capture_menu",
    "grow_menu",
    "learn_menu"
  ]

  toggle_user_settings(event){
    event.preventDefault()
    if (this.menuTarget.style.display == "none" || this.menuTarget.style.display == "") {
      this.hide_all_menus()
      this.menuTarget.style.display = "block"
    } else {
      this.hide_all_menus()
    }
  }

  toggle_insights_menu(event){
    event.preventDefault()
    if (this.insights_menuTarget.style.display == "none" || this.insights_menuTarget.style.display == "") {
      this.hide_all_menus()
      this.insights_menuTarget.style.display = "block"
    } else {
      this.hide_all_menus()
    }
  }

  toggle_capture_menu(event){
    event.preventDefault()
    if (this.capture_menuTarget.style.display == "none" || this.capture_menuTarget.style.display == "") {
      this.hide_all_menus()
      this.capture_menuTarget.style.display = "block"
    } else {
      this.hide_all_menus()
    }
  }

  toggle_grow_menu(event){
    event.preventDefault()
    if (this.grow_menuTarget.style.display == "none" || this.grow_menuTarget.style.display == "") {
      this.hide_all_menus()
      this.grow_menuTarget.style.display = "block"
    } else {
      this.hide_all_menus()
    }
  }

  toggle_learn_menu(event){
    event.preventDefault()
    if (this.learn_menuTarget.style.display == "none" || this.learn_menuTarget.style.display == "") {
      this.hide_all_menus()
      this.learn_menuTarget.style.display = "block"
    } else {
      this.hide_all_menus()
    }
  }

  hide_all_menus(){
    this.menuTarget.style.display = "none"
    this.insights_menuTarget.style.display = "none"
    this.capture_menuTarget.style.display = "none"
    this.grow_menuTarget.style.display = "none"
    this.learn_menuTarget.style.display = "none"
  }

}
