// app/javascript/controllers/backup_method_controller.js

import { Controller } from "stimulus";
import Rails from "@rails/ujs";

export default class extends Controller {

  connect() {
    console.log('Initializing Backup Method...')
    $("body").tooltip({ selector: '[data-toggle=tooltip]' });
  }

  addPaymentMethod(event) {
    event.preventDefault();

    const accountId = document.body.dataset.accountId;

    Rails.ajax({
      type: 'GET',
      url: `/app/${accountId}/payment_method/add_payment_method`,
      success: (data) => {
        this.updatePartial(data);
      },
      error: (error) => {
        console.error('Failed to fetch payment method partial:', error);
      }
    });
  }

  editPaymentMethod(event) {
    event.preventDefault();

    const paymentMethodId = event.target.dataset.paymentMethodId;
    const accountId = document.body.dataset.accountId;

    this.renderPartial(paymentMethodId, accountId);
  }

  confirmDelete(event) {
    event.preventDefault();

    const paymentMethodId = event.target.dataset.paymentMethodId;
    const accountId       = document.body.dataset.accountId;

    if (paymentMethodId && accountId) {
      const modal = document.getElementById(`confirmation-modal-${paymentMethodId}`);

      Rails.ajax({
        url: "/app/" + $("body").attr("data-account-id") + "/payment_methods/" + paymentMethodId,
        type: 'DELETE',
        success: (response) => {
          $(modal).modal('hide');
          $('.modal-backdrop').remove();
          $(`#payment-method-${paymentMethodId}`).remove();

          var message = response.message;

          Rails.ajax({
            type: 'GET',
            url: `/app/${accountId}/payment_methods`,
            success: (data) => {
              $('#current_payment_method').html(data.body.innerHTML);
              feather.replace();
              toastr.success(message);
            },
            error: (error) => {
              toastr.error('Failed to fetch payment methods');
            }
          });
        },
        error: (error) => {
          $(modal).modal('hide');
          toastr.error(error);
        }
      });
    }
  }

  renderPartial(paymentMethodId, accountId) {
    Rails.ajax({
      type: 'GET',
      url: `/app/${accountId}/payment_method/render_partial?payment_method_id=${paymentMethodId}`,
      success: (data) => {
        this.updatePartial(data);
      },
      error: (error) => {
        toastr.error(error);
      }
    });
  }

  updatePartial(data) {
    var paymentMethodContent = document.getElementById('payment-method-content');
    paymentMethodContent.innerHTML = data.body.innerHTML;
    feather.replace();
    paymentMethodContent.scrollIntoView({ behavior: "smooth", block: "center", inline: "nearest" });
    paymentMethodContent.style.boxShadow = "rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset";
  }

  disableLink(event) {
    event.preventDefault();

    const primaryButton = event.currentTarget;
    primaryButton.classList.add('disabled');
  }
}
