import { Controller } from "stimulus"
import Rails from "@rails/ujs";

export default class extends Controller {

  static targets = [

  ]

  connect() {
    let controller = this
    var quill = new Quill(controller.element, {
        theme: "snow",
        modules: {
            toolbar: [
                ["bold", "italic", "underline"],
                ["link"],
                // [{ 'align': [] }]
            ]
        }
    })

    quill.on('editor-change', function (eventName) {
      var value = $('.ql-editor').html();
      $('#description-default').val(value);
      $("#mainframe").contents().find("div.description").html(value);
    })
    
  }


}
