import { Controller } from "stimulus";
import Sortable from "sortablejs";
import Chart from 'chart.js/auto';

export default class QuickStartController extends Controller {
  account_id = document.querySelector('body').getAttribute('data-account-id');

  connect() {
    console.log('Analysis Initialized...');
    this.sortableSortable();
    this.guestPerformance(7);
    this.locationPerformance(7, 'all-locations');
    this.emailPerformance(7);
    this.qrcPerformance(7);
    this.reviewAutomatorPerformance(7, '0');
    this.topPerformingInteractions();
    this.recentBlastCampaigns();
    this.topPerformingForms();
  }
  sortableSortable() {
    const performanceModule = document.querySelector('#performance-module-sortable');
    const marketingModule = document.querySelector('#marketing-module-sortable');
    if (performanceModule) {
      Sortable.create(performanceModule, {
        draggable: '.modules-drag',
        handle: '.move-block',
        animation: 150,
        easing: "cubic-bezier(1, 0, 0, 1)",

        onUpdate: (event) => {
          const listItems = event.from.querySelectorAll('.modules-drag');
          this.update_priorities(listItems,'performance_module');
        }
      });
    }
    if (marketingModule) {
      Sortable.create(marketingModule, {
        draggable: '.modules-drag',
        handle: '.move-block',
        animation: 150,
        easing: "cubic-bezier(1, 0, 0, 1)",

        onUpdate: (event) => {
          const listItems = event.from.querySelectorAll('.modules-drag');
          this.update_priorities(listItems,'marketing_module');
        }
      });
    }
  }
  guestPerformance(numDays){
    const self = this;
    const isClicked = this.isTriggeredByHuman()
    const moduleName = 'guest-performance'
    self.callPreModule(moduleName)
    $.ajax({
      type: 'GET',
      async: true,
      url: `/app/${this.account_id}/guest_performance`,
      cache: 'false',
      data: {days: numDays, is_clicked: isClicked},
      success: function(res){
        $('.guest-performance').html(res.html);
        if(res.total_logins > 0 || res.total_guests > 0 || res.total_visits > 0){
          const ctx2 = document.getElementById("guest-bar-chart");
          const chart1 = new Chart(ctx2, {
            type: 'bar',
            data: {
              labels: self.generateDateLabels(numDays),
              datasets: [{
                label: 'Visits',
                backgroundColor: "#46D68C",
                data: res.visits_count_by_day,
              }, {
                label: 'Logins',
                backgroundColor: "#705CFE",
                data: res.logins_count_by_day,
              }, {
                label: 'Guests',
                backgroundColor: "#1DABF2",
                data: res.guests_count_by_day,
              }],
            },
            options: self.chart_options()
          });
          const chartlegend1 = document.getElementById('chart-legend1');
          chart1.data.datasets.forEach(function(dataset, index) {
              chartlegend1.innerHTML += `<li><span style="background-color:${dataset.backgroundColor}"></span>${dataset.label}</li>`;
          });
        }
        self.callPostModule(moduleName, numDays, 'guest-module-active-class')
      }
    })
  }
  locationPerformance(numDays, location){
    const self = this;
    const isClicked = this.isTriggeredByHuman()
    const moduleName = 'location-performance'
    self.callPreModule(moduleName)
    $.ajax({
      type: 'GET',
      async: true,
      url: `/app/${this.account_id}/location_performance`,
      cache: 'false',
      data: {days: numDays, location: location, is_clicked: isClicked},
      success: function(res){
        $('.location-performance').html(res.html);
        if(res.total_logins > 0 || res.total_guests > 0 || res.total_visits > 0){
          const ctx3 = document.getElementById("location-bar-chart");
          const chart2 = new Chart(ctx3, {
            type: 'bar',
            data: {
              labels: self.generateDateLabels(numDays),
              datasets: [{
                label: 'Visits',
                backgroundColor: "#46D68C",
                data: res.visits_count_by_day,
              }, {
                label: 'Logins',
                backgroundColor: "#705CFE",
                data: res.logins_count_by_day,
              }, {
                label: 'Guests',
                backgroundColor: "#1DABF2",
                data: res.guests_count_by_day,
              }],
            },
            options: self.chart_options()
          });
          const chartlegend2 = document.getElementById('chart-legend2');
          chart2.data.datasets.forEach(function(dataset, index) {
              chartlegend2.innerHTML += `<li><span style="background-color:${dataset.backgroundColor}"></span>${dataset.label}</li>`;
          });
        }
        self.callPostModule(moduleName, numDays, 'location-module-active-class');
      }
    })
  }
  emailPerformance(numDays){
    const self = this;
    const isClicked = this.isTriggeredByHuman()
    const moduleName = 'email-performance'
    self.callPreModule(moduleName)
    $.ajax({
      type: 'GET',
      async: true,
      url: `/app/${this.account_id}/email_performance`,
      cache: 'false',
      data: {days: numDays, is_clicked: isClicked},
      success: function(res){
        $('.email-performance').html(res.html);
        if(res.total_opened > 0 || res.total_clicked > 0 || res.total_sent > 0 || res.total_unsubscribed > 0){
          const ctx4 = document.getElementById("email-bar-chart");
          const chart3 = new Chart(ctx4, {
            type: 'bar',
            data: {
              labels: self.generateDateLabels(numDays),
              datasets: [{
                label: 'Total Sents',
                backgroundColor: "#1DABF2",
                data: res.sent_count_by_day,
              },{
                label: 'Total Clicks',
                backgroundColor: "#46D68C",
                data: res.clicked_count_by_day,
              }, {
                label: 'Total Opens',
                backgroundColor: "#705CFE",
                data: res.opened_count_by_day,
              }, {
                label: 'Total Unsubscribed',
                backgroundColor: "#FF7979",
                data: res.unsubscribed_count_by_day,
              }],
            },
            options: self.chart_options()
          });
          const chartlegend3 = document.getElementById('chart-legend3');
          chart3.data.datasets.forEach(function(dataset, index) {
              chartlegend3.innerHTML += `<li><span style="background-color:${dataset.backgroundColor}"></span>${dataset.label}</li>`;
          });
        }
        self.callPostModule(moduleName, numDays, 'email-module-active-class');
      }
    })
  }  
  qrcPerformance(numDays){
    const self = this;
    const isClicked = this.isTriggeredByHuman()
    const moduleName = 'qrc-performance'
    self.callPreModule(moduleName)
    $.ajax({
      type: 'GET',
      async: true,
      url: `/app/${this.account_id}/qrc_performance`,
      cache: 'false',
      data: {days: numDays, is_clicked: isClicked},
      success: function(res){
        $('.qrc-performance').html(res.html);
        if(res.total_scans > 0 || res.total_names > 0){
          const ctx5 = document.getElementById("qrc-bar-chart");
          const chart4 = new Chart(ctx5, {
            type: 'bar',
            data: {
              labels: self.generateDateLabels(numDays),
              datasets: [{
                label: 'Name',
                backgroundColor: "#46D68C",
                data: res.name_count_by_day,
              }, {
                label: 'Scan',
                backgroundColor: "#1DABF2",
                data: res.scan_count_by_day,
              }],
            },
            options: self.chart_options()
          });
          const chartlegend4 = document.getElementById('chart-legend4');
          chart4.data.datasets.forEach(function(dataset, index) {
              chartlegend4.innerHTML += `<li><span style="background-color:${dataset.backgroundColor}"></span>${dataset.label}</li>`;
          });
        }
        self.callPostModule(moduleName, numDays, 'qrc-module-active-class');
      }
    })
  }
  reviewAutomatorPerformance(numDays, rating){
    const self = this;
    const isClicked = this.isTriggeredByHuman()
    const moduleName = 'review-automator-performance'
    self.callPreModule(moduleName)
    $.ajax({
      type: 'GET',
      async: true,
      url: `/app/${this.account_id}/review_automator_performance`,
      cache: 'false',
      data: {days: numDays, rating: rating, is_clicked: isClicked},
      success: function(res){
        $('.review-automator-performance').html(res.html);
        if (res.total_reviews > 0 || res.total_opens > 0 || res.total_clicks > 0 || res.any_review){
          const ctx6 = document.getElementById("automator-bar-chart");
          const chart5 = new Chart(ctx6, {
            type: 'bar',
            data: {
              labels: self.generateDateLabels(numDays),
              datasets: [{
                label: 'Opens',
                backgroundColor: "#46D68C",
                data: res.opens_count_by_day,
              }, {
                label: 'Clicks',
                backgroundColor: "#705CFE",
                data: res.clicks_count_by_day,
              }, {
                label: 'New Reviews',
                backgroundColor: "#1DABF2",
                data: res.reviews_count_by_day,
              }],
            },
            options: self.chart_options()
          });
          const chartlegend5 = document.getElementById('chart-legend5');
          chart5.data.datasets.forEach(function(dataset, index) {
              chartlegend5.innerHTML += `<li><span style="background-color:${dataset.backgroundColor}"></span>${dataset.label}</li>`;
          });
        }
        self.callPostModule(moduleName, numDays, 'review-module-active-class');
      }
    })
  }
  
  topPerformingInteractions(){
    $.ajax({
      type: 'GET',
      async: true,
      url: `/app/${this.account_id}/top_performing_interactions`,
      cache: 'false',
      data: {},
      success: function(res){
        $('.top-performing-interactions').html(res);
      }
    })
  }
  recentBlastCampaigns(){
    $.ajax({
      type: 'GET',
      async: true,
      url: `/app/${this.account_id}/recent_blast_campaigns`,
      cache: 'false',
      data: {},
      success: function(res){
        $('.recent-blast-campaigns').html(res);
      }
    })
  }
  topPerformingForms(){
    $.ajax({
      type: 'GET',
      async: true,
      url: `/app/${this.account_id}/top_performing_forms`,
      cache: 'false',
      data: {},
      success: function(res){
        $('.top-performing-forms').html(res);
      }
    })
  }

  generateDateLabels(numDays) {
    const labels = [];
    for (let i = numDays - 1; i >= 0; i--) {
      const date = new Date();
      date.setDate(date.getDate() - i);
      const day = date.getDate().toString().padStart(2, '0');
      const monthName = new Intl.DateTimeFormat('en', { month: 'short' }).format(date);
      labels.push(`${day}-${monthName}`);
    }
    return labels;
  }
  statsByLocation() {
    this.locationPerformance(7, this.getSelectedOptionId("lpSelect"));
  }
  statsByReview() {
    this.reviewAutomatorPerformance(7, this.getSelectedOptionId("statSelect"));
  }
  render_performance_module(event) {
    let data = JSON.parse(event.target.dataset.quickstartActiveParam);
    let numDays = parseInt(data.days);
    switch (data.module) {
      case "guest":
        this.guestPerformance(numDays);
        break;
      case "email":
        this.emailPerformance(numDays);
        break;
      case "qrc":
        this.qrcPerformance(numDays);
        break;
      case "review":
        this.reviewAutomatorPerformance(numDays, this.getSelectedOptionId("statSelect"));
        break;
      case "location":
        this.locationPerformance(numDays, this.getSelectedOptionId("lpSelect"));
        break;
    }
  }
  getSelectedOptionId(selectId) {
    const element = document.getElementById(selectId);
    return element.options[element.selectedIndex].id;
  }  
  update_priorities(data, module_name){
    let classNames = [];
    for (let i = 0; i < data.length; i++) {
      let className = data[i].className.split(' ')[0];
      classNames.push(className);
    }
    $.ajax({
      type: 'POST',
      async: true,
      url: `/app/${this.account_id}/update_priorities`,
      cache: 'false',
      data: {classNames: classNames, module_name: module_name},
      success: function(res){
        console.log("Sucessfully Update Dashboard");
      }
    })
  }
  chart_options(){
    return {
      interaction: {
        intersect: false,
        mode: 'index',
        backgroundColor: '#ffffff',
        padding: 10,
        titleMarginBottom: 10,
        bodySpacing: 5,
        bodyColor: '#344047',
        titleColor: '#344047',
        titleFont: {
          size: 14,
          weight: "500",
          family: "'Circular Std', sans-serif",
        },
        bodyFont: {
          size: 14,
          weight: "500",
          family: "'Circular Std', sans-serif",
        }
      },
      plugins: {
        legend: {
          display: false,
        }
      },
      scales: {
        x: {
          stacked: true,
          grid: {
            display: false
          }
        },
        y: {
          stacked: true,
          grid: {
            display: true
          }
        }
      },
      maintainAspectRatio: false,
      responsive: true
    };
  }
  updateActiveElement(numDays, moduleActiveClass){
    let activeIndex;
    if (numDays === 7) {
      activeIndex = 1;
    } else if (numDays === 30) {
      activeIndex = 2;
    } else if (numDays === 60) {
      activeIndex = 3;
    } else {
      activeIndex = 0;
    }
    const $moduleActiveElement = $('#' + moduleActiveClass);
      if ($moduleActiveElement.length) {
        $moduleActiveElement.find('li').removeClass('active');
      const $activeLiElement = $moduleActiveElement.find('li:nth-child(' + activeIndex + ')');
      if ($activeLiElement.length) {
        $activeLiElement.addClass('active');
      }
    }
  }

  callPreModule(moduleName){
    this.disableActions(moduleName);
    this.setOpacity(moduleName);
    this.createLoader(moduleName);
  }

  callPostModule(moduleName, numDays, activeModuleName){
    this.updateActiveElement(numDays, activeModuleName);
    this.removeLoader(moduleName);
    this.resetOpacity(moduleName);
    this.enableActions(moduleName);
  }

  // Function to set/reset opacty under tab-content
  setOpacity(moduleName) {
    const tabContent = document.querySelector('.'+moduleName+ ' .tab-content');
    if (tabContent) {
      tabContent.style.opacity = '0.5'; // Set opacity to 0% (adjust as needed)
    }
  }
  resetOpacity(moduleName) {
    const tabContent = document.querySelector('.'+moduleName+ ' .tab-content');
    if (tabContent) {
      tabContent.style.opacity = ''; // Set opacity to 0% (adjust as needed)
    }
  }
  // Function to enable/disbale all actions under tab-content
  enableActions(moduleName) {
    const tabContent = document.querySelector('.'+moduleName+ ' .tab-content');
    if (tabContent) {
      const actions = tabContent.querySelectorAll('a, button');
      actions.forEach(action => {
        if (action.tagName.toLowerCase() === 'a') {
          const originalHref = action.dataset.originalHref;
          if(originalHref){action.setAttribute('href', originalHref);}
        } else {
          action.disabled = false; // enable the button
        }
      });
    }
  }

  disableActions(moduleName) {
    const tabContent = document.querySelector('.'+moduleName+ ' .tab-content');
    if (tabContent) {
      const actions = tabContent.querySelectorAll('a, button');
      actions.forEach(action => {
        if (action.tagName.toLowerCase() === 'a') {
          action.removeAttribute('href'); // Remove the href attribute to disable the link
        } else {
          action.disabled = true; // Disable the button
        }
      });
    }
  }
  // Function to create/remove the loader elements
  createLoader(moduleName) {
    const tabContent = document.querySelector('.' + moduleName + ' .tab-content');
    if(tabContent){
      let loader = document.createElement('div');
      loader.classList.add('data-analysis-loader');
      tabContent.insertAdjacentElement('beforebegin', loader);
    }

  }
  removeLoader(moduleName) {
    const loader = document.querySelector('.'+moduleName+ ' .data-analysis-loader');
    if (loader) {
      loader.remove();
    }
  }

  isTriggeredByHuman(){
    try {
      return event.currentTarget != document
    }
    catch {
      return false
    }
  }

}
