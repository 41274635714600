import { Controller } from "stimulus"
import Rails from "@rails/ujs";

export default class extends Controller {

  static targets = [
    "input"
  ]

  connect(){
    this.active = (this.inputTarget.value == "true")
  }

  toggle(){
    if (this.active){
      this.active = false
      this.inputTarget.value = false
      this.element.classList.remove("selected")
    } else {
      this.active = true
      this.inputTarget.value = true
      this.element.classList.add("selected")
    }
  }

}
