import { Controller } from "stimulus"
import Rails from "@rails/ujs";

export default class extends Controller {

  static targets = [
	  "cta",
	  "sideBar",
	  "content",
  ]

  connect() {
	  if($('.article_content').length != 0 ){
	  	this.primaryColor = "rgba(" + $('.article.main').data('primary-color') + ", 1);";

	  	this.sideBarStart = $(this.sideBarTarget).offset().top;
	  	this.updateVariables();

	    // SET SIDEBAR HEIGHT
	    $('#sidebar').height(this.contentHeight + (this.contentStart - this.sideBarStart));
	  };
  }

  updateVariables() {
  	// GET NECESSARY POSITIONING INTS
    this.contentStart = $(this.contentTarget).offset().top;
    this.contentHeight = $(this.contentTarget).innerHeight();
    this.contentEnd = (this.contentStart + this.contentHeight);

    this.sideBarRightOffset = ($(window).width() - ($(this.sideBarTarget).offset().left + $(this.sideBarTarget).outerWidth()));
    this.sideBarHeight = $(this.sideBarTarget).height();
    this.sideBarWidth = $(this.sideBarTarget).outerWidth();
  }

  updatePosition(){
  	this.updateVariables();
	  var currentScroll = $(window).scrollTop();
	  $(this.ctaTarget).attr('style', 'background:' + this.primaryColor + ';width:' + this.sideBarWidth + 'px;right:' + (this.sideBarRightOffset == 0 ? 10 : this.sideBarRightOffset) + 'px;');

	  if (currentScroll < this.sideBarStart) {
	  	console.log("top");
	    // START, SHOW TOP
	    $(this.sideBarTarget).removeClass('fixed-bottom');
	    $(this.sideBarTarget).removeClass('fixed-scroll');
	  } else if ((currentScroll > this.sideBarStart) && (currentScroll < (this.contentEnd - this.sideBarHeight))) {
	    // MIDDLE, FIX SIDEBAR
	    $(this.sideBarTarget).removeClass('fixed-bottom');
	    $(this.sideBarTarget).addClass('fixed-scroll');
	  } else if (currentScroll > (this.contentEnd - this.sideBarHeight)) {
	    // END, SHOW BOTTOM
	    $(this.sideBarTarget).addClass('fixed-bottom');
	    $(this.sideBarTarget).removeClass('fixed-scroll');
	  }
  }

}