import { Controller } from "stimulus"
import Rails from "@rails/ujs";

export default class extends Controller {
  static targets = ["option_container", "option", "mode", "toggle"]

  toggle_options(){
    if (['3'].includes(this.modeTarget.value)){
      this.toggleTargets.forEach(function(element){
        element.style.display = "block"
      })
    } else {
      this.toggleTargets.forEach(function(element){
        element.style.display = "none"
      })
    }
  }

  new_option(){
    let container = document.createElement("div")
    container.setAttribute("data-controller", "custom-fields--remove-option")
    let label = document.createElement('label')
    label.setAttribute("for", "custom_field_options")
    label.setAttribute("class", "m-t-10")
    label.textContent = `Option ${this.option_containerTarget.querySelectorAll("input").length + 1}`
    container.appendChild(label)
    let input_group = document.createElement("div")
    input_group.setAttribute("class", "input-group")
    let input = document.createElement('input')
    input.setAttribute("multiple", "multiple")
    input.setAttribute("class", "form-control")
    input.setAttribute("type", "text")
    input.setAttribute("name", "custom_field[options][]")
    input.setAttribute("data-target",  `${this.identifer}.option`)
    input_group.appendChild(input)
    let input_group_append = document.createElement("div")
    input_group_append.setAttribute("class", "input-group-append")
    let button = document.createElement("button")
    button.setAttribute("type", "button")
    button.setAttribute("class", "btn btn-light waves-effect")
    button.setAttribute("name", "button")
    button.setAttribute("data-action", "click->custom-fields--remove-option#remove")
    let icon = document.createElement("i")
    icon.setAttribute("data-feather", "x")
    icon.setAttribute("style", "position:relative;top:3px;left:1px;")
    icon.setAttribute("height", "18")
    icon.setAttribute("width", "18")
    button.appendChild(icon)
    input_group_append.appendChild(button)
    input_group.appendChild(input_group_append)
    container.appendChild(input_group)
    this.option_containerTarget.appendChild(container)
    feather.replace()
  }

}
