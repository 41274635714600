import { Controller } from "stimulus"

export default class extends Controller {

  static targets = [
  ]

  initialize() {
    this.can_enable()
  }

  can_enable(){
    let enabled = false
    document.querySelectorAll("[name*='[facebook]']").forEach(function(elem){
      if (elem.value.length > 0){
        enabled = true
      }
    })
    document.querySelectorAll("[name*='[twitter]']").forEach(function(elem){
      if (elem.value.length > 0){
        enabled = true
      }
    })
    if (!enabled) {
      $('#checkbox1').attr("checked", false);
      $('p.text-danger').show();
    } else if (enabled) {
      $('p.text-danger').hide();
    }
  }


}
