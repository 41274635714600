import { Controller } from "stimulus"

export default class extends Controller {

  static targets = [

  ]

  connect(){

    this.changed = false

    jQuery('#mainframe').attr("style", "overflow:hidden;min-height:" + (jQuery(window).height() - jQuery('#topnav').height()) + "px;")
    jQuery(window).on('resize', function(){
      jQuery('#mainframe').attr("style", "overflow:hidden;min-height:" + (jQuery(window).height() - jQuery('#topnav').height()) + "px;")
    })

    this.add_plugins()
  }

  async getTranslatedWords(title, text, confirmButton, cancelButton) {
    const result = await Weglot.translate({
      words: [
        { t: 1, w: title },
        { t: 1, w: text },
        { t: 1, w: confirmButton },
        { t: 1, w: cancelButton },
      ],
      languageTo: Weglot.getCurrentLang(),
    });
    return result;
  }

  submit_form(){
    jQuery('form').submit()
  }

  form_changed(){
    this.changed = true
  }

  async redirect(event){
    if(this.changed === true){
      event.preventDefault()
      var link = jQuery(event.currentTarget).attr("href")

      let title = 'Changes not saved';
      let text  = 'Save or discard your changes before continuing';
      let confirmButton = 'Save & Continue';
      let cancelButton  = 'Discard';

      try {
        const translatedWords = await this.getTranslatedWords(title, text, confirmButton, cancelButton);

        if (translatedWords.length == 4) {
          [title, text, confirmButton, cancelButton] = translatedWords;
        }

        swal({
            title: title,
            text: text,
            type: 'warning',
            showCancelButton: true,
            confirmButtonText: confirmButton,
            cancelButtonText: cancelButton,
            confirmButtonClass: 'btn btn-lg btn-secondary waves-effect waves-light',
            cancelButtonClass: 'btn btn-lg btn-danger waves-effect waves-light m-l-10',
            buttonsStyling: false
        }).then(function () {
            jQuery('#redirect_path').val(link);
            jQuery('form').submit();
        }, function (dismiss) {
            // dismiss can be 'cancel', 'overlay',
            // 'close', and 'timer'
            if (dismiss === 'cancel') {
              window.location.href = link;
            }
        })
      } catch (e) {}
    }
  }

  add_plugins(){
    let controller = this

    jQuery('.sortable h4').editable({
      type: 'text',
      name: 'Field Name',
      title: 'Enter Field Name',
      mode: 'inline',
      success: function(response, newValue) {
        jQuery(this).closest(".form-field").attr('data-field-label', newValue)
        controller.compileJson()
      }
    })

    jQuery(".sortable").sortable({
      connectWith: ".sortable",
      items: ".card-draggable",
      revert: !0,
      placeholder:"card-draggable-placeholder",
      forcePlaceholderSize: !0,
      opacity: .77,
      cursor:"move",
      stop: function(event, ui) {
        controller.compileJson()
      }
    })
  }

  update_title(event){
    $("#mainframe").contents().find("h1").text(jQuery(event.currentTarget).val())
    this.form_changed()
  }

  add_field(event) {
    jQuery("#form-fields-modal").modal('show')
    jQuery("#add-fields-modal").modal('hide')

    if (jQuery("#user_form_fields").val().indexOf(jQuery(event.currentTarget).parent().attr('data-field-id')) >= 0){
      toastr.warning("This field is already in your form.")
    } else {
      this.append_field(jQuery(event.currentTarget).parent().attr('data-field-id'), jQuery(event.currentTarget).parent().attr('data-field-label'), jQuery(event.currentTarget).parent().attr("data-mode"), jQuery(event.currentTarget).parent().attr("data-field-value"), jQuery(event.currentTarget).parent().attr("data-field-option"))
    };
  }
  append_field(id, label, mode, value, option){
    if (id.includes("custom")){
      jQuery(".sortable").append('<div class="card-box card-draggable form-field" data-field-id="' + id + '" data-field-label="' + label + '" data-mode="' + mode + '" data-field-required="true"><div class="card-body"><div class="row"><div class="col-sm-1 col-xs-1 text-center"><i class="zmdi zmdi-apps"></i></div><div class="col-sm-7 col-xs-5 text-left"><h4 class="card-title">' + label + '</h4></div><div class="col-sm-4 col-xs-6 text-right"><i class="zmdi zmdi-alert-circle" style="position:relative;left:-10px;" data-toggle="tooltip" data-placement="top" title="" data-original-title="Your guests will be required to provide this field." data-controller="setups--step-two" data-action="click->setups--step-two#unrequire_field" data-field="' + id + '"></i> <i class="zmdi zmdi-delete" data-controller="setups--step-two" data-action="click->setups--step-two#remove_field"></i></div></div></div></div>')
    } else {
      jQuery(".sortable").append('<div class="card-box card-draggable form-field" data-field-id="' + id + '" data-field-label="' + label + '" data-field-value="' + value + '" data-field-option="' + option + '" data-field-required="true"><div class="card-body"><div class="row"><div class="col-sm-1 col-xs-1 text-center"><i class="zmdi zmdi-apps"></i></div><div class="col-sm-7 col-xs-5 text-left"><h4 class="card-title">' + label + '</h4></div><div class="col-sm-4 col-xs-6 text-right"><i class="zmdi zmdi-alert-circle" style="position:relative;left:-10px;" data-toggle="tooltip" data-placement="top" title="" data-original-title="Your guests will be required to provide this field." data-controller="setups--step-two" data-action="click->setups--step-two#unrequire_field" data-field="' + id + '"></i> <i class="zmdi zmdi-delete" data-controller="setups--step-two" data-action="click->setups--step-two#remove_field"></i></div></div></div></div>')
    }

    this.add_plugins()
    this.compileJson()
  }

  new_field_modal(){
    jQuery("#form-fields-modal").modal('hide')
  }

  add_custom_field_modal(){
    jQuery("#add-fields-modal").modal('hide')
    jQuery("#new-field-modal").modal('show')
  }

  rename_custom_field(event){
    jQuery("#rename-field-modal #custom_field_label").val($(event.currentTarget).attr('data-field-label'))
    jQuery("#rename-field-modal form").attr("action", ('login/field/' + $(event.currentTarget).attr('data-field-id')))
    jQuery("#add-fields-modal").modal('hide')
    jQuery("#rename-field-modal").modal('show')
  }

  login_method(event){
    var active = jQuery(event.currentTarget).hasClass("selected")
    var elements = {"facebook" : ".s-but.facebook", "twitter" : ".s-but.twitter", "form" : ".manual-form, #or", "google" : ".s-but.google", "apple" : ".s-but.apple", "discord" : ".s-but.discord"}
    if (active) {
      jQuery(event.currentTarget).removeClass("selected")
      jQuery(event.currentTarget).find("input").val("0")
      jQuery("#mainframe").contents().find(elements[$(event.currentTarget).data("method")]).parent().attr("style", "display:none;")
    } else {
      jQuery(event.currentTarget).addClass("selected")
      jQuery(event.currentTarget).find("input").val("1")
      jQuery("#mainframe").contents().find(elements[$(event.currentTarget).data("method")]).parent().attr("style", "display:block;")
    }
    this.checkSocialLogins()
  }

  checkSocialLogins(){
    var fb_checked = (jQuery('#user_login_one_attributes_facebook_enabled').val() == 1 || jQuery('#user_login_one_attributes_facebook_enabled').val() == "true" ? true : false)
    var tw_checked = (jQuery('#user_login_one_attributes_twitter_enabled').val() == 1 || jQuery('#user_login_one_attributes_twitter_enabled').val() == "true" ? true : false)
    var google_checked = (jQuery('#user_login_one_attributes_google_enabled').val() == 1 || jQuery('#user_login_one_attributes_google_enabled').val() == "true" ? true : false)
    var discord_checked = (jQuery('#user_login_one_attributes_discord_enabled').val() == 1 || jQuery('#user_login_one_attributes_discord_enabled').val() == "true" ? true : false)
    var apple_checked = (jQuery('#user_login_one_attributes_apple_enabled').val() == 1 || jQuery('#user_login_one_attributes_apple_enabled').val() == "true" ? true : false)
    if(fb_checked || tw_checked || google_checked || apple_checked || discord_checked){
      jQuery("#mainframe").contents().find('#or').show()
    } else {
      jQuery("#mainframe").contents().find('#or').hide()
    }
    var count = 0
    if (fb_checked) {
      count += 1
    }
    if (tw_checked) {
      count += 1
    }
    if (google_checked) {
      count += 1
    }
    if (discord_checked) {
      count += 1
    }
    if (apple_checked) {
      count += 1
    }
    console.log(count)
    if (count > 2){
      jQuery("#mainframe").contents().find('#login-buttons .columns').each(function(){
        let str = $(this).find(".b-2").html()
        $(this).find(".b-2").html(str.replace('Sign In', ''))
        $(this).find(".b-2 i").attr("style", "margin-right: 0px;")
      })
    } else {
      jQuery("#mainframe").contents().find('#login-buttons .columns').each(function(){
        let str = $(this).find(".b-2").html()
        $(this).find(".b-2").html(str.replace('Sign In', '') + "Sign In")
        $(this).find(".b-2 i").attr("style", "margin-right:.75rem !important;")
      })
    }
    this.form_changed()
  }

  modal_back_to_form_fields(){
    jQuery("#form-fields-modal").modal('show')
    jQuery("#add-fields-modal").modal('hide')
    jQuery("#new-field-modal").modal('hide')
    jQuery("#rename-field-modal").modal('hide')
  }

  modal_back_to_add_fields(){
    jQuery("#add-fields-modal").modal('show')
    jQuery("#new-field-modal").modal('hide')
    jQuery("#form-fields-modal").modal('hide')
    jQuery("#rename-field-modal").modal('hide')
  }

  async remove_custom_field(event){
    var id = jQuery(event.currentTarget).attr('data-field-id').replace("custom_", "")
    var controller = this

    let title = 'Are you sure?';
    let text  = 'Removing cannot be undone';
    let confirmButton = 'Yes, remove this field!';
    let cancelButton  = 'Cancel';

    try {
      const translatedWords = await this.getTranslatedWords(title, text, confirmButton, cancelButton);

      if (translatedWords.length == 4) {
        [title, text, confirmButton, cancelButton] = translatedWords;
      }

      swal({
        title: title,
        text: text,
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: confirmButton,
        cancelButtonText: cancelButton,
        confirmButtonClass: 'btn btn-lg btn-danger waves-effect waves-light m-t-20',
        cancelButtonClass: 'btn btn-lg btn-transparent waves-effect waves-light m-l-10 m-t-20',
        buttonsStyling: false
      }).then(function () {
        $.ajax({
          url: ('login/field/' + id),
          type: 'DELETE',
          success: function(result) {
            // Do something with the result
          }
        });
      }, function (dismiss) {
        if (dismiss === 'cancel') {
          // window.location.href = link;
        }
      })
    } catch(e) {}
  }

  remove_field(event){
    var element = jQuery(event.currentTarget).closest('.form-field')
    if (element.attr("data-field-id") == "email"){
      let valid = false
      document.querySelectorAll(".form-field").forEach(function(elem){
        if (elem.getAttribute("data-field-id") == "phone_number"){
          valid = true
        }
      })
      if (valid){
        this.complete_field_removal(element)
      } else {
        toastr.error("You must include the phone number field to remove email")
      }
    } else if (element.attr("data-field-id") == "phone_number"){
      let valid = false
      document.querySelectorAll(".form-field").forEach(function(elem){
        if (elem.getAttribute("data-field-id") == "email"){
          valid = true
        }
      })
      if (valid){
        this.complete_field_removal(element)
      } else {
        toastr.error("You must include the email field to remove phone number")
      }
    } else if (element.attr("data-field-id") == "full_name"){
      let valid = false
      document.querySelectorAll(".form-field").forEach(function(elem){
        if (elem.getAttribute("data-field-id") == "first_name"){
          valid = true
        }
      })
      if (valid){
        this.complete_field_removal(element)
      } else {
        toastr.error("You must include the first name field to remove full name")
      }
    } else if (element.attr("data-field-id") == "first_name"){
      let valid = false
      document.querySelectorAll(".form-field").forEach(function(elem){
        if (elem.getAttribute("data-field-id") == "full_name"){
          valid = true
        }
      })
      if (valid){
        this.complete_field_removal(element)
      } else {
        toastr.error("You must include the full name field to remove first name")
      }
    } else {
      this.complete_field_removal(element)
    }
  }

  async complete_field_removal(element){
    var controller = this

    let title = 'Are you sure?';
    let text  = 'This field will be removed';
    let confirmButton = 'Yes, remove it!';
    let cancelButton  = 'Cancel';

    try {
      const translatedWords = await this.getTranslatedWords(title, text, confirmButton, cancelButton);

      if (translatedWords.length == 4) {
        [title, text, confirmButton, cancelButton] = translatedWords;
      }

      swal({
          title: title,
          text: text,
          type: 'warning',
          showCancelButton: true,
          confirmButtonText: confirmButton,
          cancelButtonText: cancelButton,
          confirmButtonClass: 'btn btn-lg btn-danger waves-effect waves-light m-t-20',
          cancelButtonClass: 'btn btn-lg btn-transparent waves-effect waves-light m-l-10 m-t-20',
          buttonsStyling: false
      }).then(function () {
        jQuery(element).remove()
        controller.compileJson()
      }, function (dismiss) {
        //
      })
    } catch(e) {}
  }

  unrequire_field(event){
    // If unrequiring phone or email, check the other is required first.
    const field = event.currentTarget.getAttribute("data-field")
    if (field == "email") {
      let valid = false
      document.querySelectorAll(".form-field").forEach(function(elem){
        if (elem.getAttribute("data-field-id") == "phone_number" && elem.getAttribute("data-field-required") == "true"){
          valid = true
        }
      })
      if (valid){
        this.complete_unrequire(field, event.currentTarget)
      } else {
        toastr.warning("You must include phone number as a required field to make email optional")
      }
    } else if (field == "phone_number") {
      let valid = false
      document.querySelectorAll(".form-field").forEach(function(elem){
        if (elem.getAttribute("data-field-id") == "email" && elem.getAttribute("data-field-required") == "true"){
          valid = true
        }
      })
      if (valid){
        this.complete_unrequire(field, event.currentTarget)
      } else {
        toastr.warning("You must include email as a required field to make email optional")
      }
    } else if (field == "full_name") {
      let valid = false
      document.querySelectorAll(".form-field").forEach(function(elem){
        if (elem.getAttribute("data-field-id") == "first_name" && elem.getAttribute("data-field-required") == "true"){
          valid = true
        }
      })
      if (valid){
        this.complete_unrequire(field, event.currentTarget)
      } else {
        toastr.warning("You must include first name as a required field to make full name optional")
      }
    } else if (field == "first_name") {
      let valid = false
      document.querySelectorAll(".form-field").forEach(function(elem){
        if (elem.getAttribute("data-field-id") == "full_name" && elem.getAttribute("data-field-required") == "true"){
          valid = true
        }
      })
      if (valid){
        this.complete_unrequire(field, event.currentTarget)
      } else {
        toastr.warning("You must include full name as a required field to make first name optional")
      }
    } else {
      this.complete_unrequire(field, event.currentTarget)
    }

  }

  complete_unrequire(field, target){
    const tooltip_placement = target.getAttribute("data-placement")
    document.querySelector(`.sortable .form-field[data-field-id="${field}"]`).setAttribute("data-field-required", "false")
    let elem = document.createElement("i")
    elem.classList.add("zmdi")
    elem.classList.add("zmdi-alert-circle-o")
    elem.setAttribute("style", "position:relative;left:-10px;")
    elem.setAttribute("data-field", field)
    elem.setAttribute("data-toggle", "tooltip")
    elem.setAttribute("data-placement", tooltip_placement)
    elem.setAttribute("data-original-title", "Your guests will not be required to provide this field.")
    elem.setAttribute("data-controller", "setups--step-two")
    elem.setAttribute("data-action", "click->setups--step-two#require_field")
    jQuery(target).mouseout()
    target.replaceWith(elem)
    this.compileJson()
    $('[data-toggle="tooltip"]').tooltip()
  }

  require_field(event){
    const field = event.currentTarget.getAttribute("data-field")
    const tooltip_placement = event.currentTarget.getAttribute("data-placement")
    document.querySelector(`.sortable .form-field[data-field-id="${field}"]`).setAttribute("data-field-required", "true")
    let elem = document.createElement("i")
    elem.classList.add("zmdi")
    elem.classList.add("zmdi-alert-circle")
    elem.setAttribute("style", "position:relative;left:-10px;")
    elem.setAttribute("data-field", field)
    elem.setAttribute("data-toggle", "tooltip")
    elem.setAttribute("data-placement", tooltip_placement)
    elem.setAttribute("data-original-title", "Your guests will be required to provide this field.")
    elem.setAttribute("data-controller", "setups--step-two")
    elem.setAttribute("data-action", "click->setups--step-two#unrequire_field")
    jQuery(event.currentTarget).mouseout()
    event.currentTarget.replaceWith(elem)
    this.compileJson()
    $('[data-toggle="tooltip"]').tooltip()
  }

  compileJson(){
    var array = []
    var elements = $('#form-fields-modal .sortable .card-box');
    [].forEach.call(elements, function( el, index ) {
      var el_json = {}
      var field_index = (index + 1);
      var field_id = $(el).attr('data-field-id');
      var field_label = $(el).attr('data-field-label');
      var field_required = $(el).attr('data-field-required');
      var mode = $(el).attr('data-mode')

      if (field_id === 'phone_number') {
        var option = $(el).attr('data-field-option');
        var value = $(el).attr('data-field-value');

        if (option === undefined && value === undefined) {
          option = $("#mainframe").contents().find("#guest_code option:selected").text()
          value  = $("#mainframe").contents().find("#guest_code").val()
        }

        el_json["option"] = option;
        el_json["value"] = value;
      }
      el_json["index"] = field_index;
      el_json["id"] = field_id;
      el_json["label"] = field_label;
      el_json["required"] = field_required;
      el_json["mode"] = mode;
      array.push(el_json);
    });
    $("#user_form_fields").val(JSON.stringify(array));
    this.renderPreview(array);
  }

  renderPreview(array){
    this.form_changed()
    var html = ""
    array.forEach(function (e, index) {
      switch(e.id){
        case 'full_name':
          html += `<div class="form-item ${e.active ? 'active' : ''}"><label class="${e.required === 'true' ? 'f-required' : ''}" for="human_full_name">${e.label}</label><input placeholder="" autocomplete="false" data-validetta="required,regExp[full_name]" type="text" name="human[full_name]" id="human_full_name"></div>`;
        break;
        case 'first_name':
          html += `<div class="form-item"><label class="${e.required === 'true' ? 'f-required' : ''}" for="human_full_name">${e.label}</label><input placeholder="" autocomplete="false" data-validetta="required,regExp[full_name]" type="text" name="human[full_name]" id="human_full_name"></div>`;
        break;
        case 'last_name':
          html += `<div class="form-item"><label class="${e.required === 'true' ? 'f-required' : ''}" for="human_full_name">${e.label}</label><input placeholder="" autocomplete="false" data-validetta="required,regExp[full_name]" type="text" name="human[full_name]" id="human_full_name"></div>`;
        break;
        case 'email':
          html += `<div class="form-item"><label class="${e.required === 'true' ? 'f-required' : ''}" for="human_email">${e.label}</label><input placeholder="" autocomplete="false" data-validetta="required,regExp[email_address],remote[validate_email]" type="text" name="human[email]" id="human_email"></div>`;
        break;
        case 'gender':
          html += `<div class="form-item"><label class="${e.required === 'true' ? 'f-required' : ''}" for="human_gender">${e.label}</label><div class="row collapse" id="gender-picker"><div class="small-12 columns select"><select data-validetta="required" name="human[gender]" id="human_gender"><option value="">Gender</option></select></div></div></div>`;
        break;
        case 'phone_number':
          html += `<div class="form-item"><label class="${e.required == 'true' ? 'f-required' : ''}" for="human_Phone">${e.label}</label><div class="row collapse" id="phone-picker"><div class="small-5 columns select"><select data-validetta="required" name="human[code]" id="human_code"><option selected="selected" value="+${e.value}">${e.option}</option></select></div><div class="columns text"><input data-validetta="required,number,maxLength[15]" type="tel" name="human[number]" id="human_number"></div><input autocomplete="false" type="hidden" name="human[phone]" id="human_phone"></div></div>`;
        break;
        case 'country':
          html += `<div class="form-item"><label class="${e.required === 'true' ? 'f-required' : ''}" for="human_country">${e.label}</label><div class="row collapse" id="country-picker"><div class="small-12 columns select"><select data-validetta="required" name="human[country]" id="human_country"><option value="">Country</option></select></div></div></div>`;
        break;
        case 'date_of_birth':
          html += `<div class="form-item"><label class="${e.required === 'true' ? 'f-required' : ''}" for="human_birthday">${e.label}</label><div class="row collapse" id="dob-picker"><div class="small-3 columns select"><select data-validetta="required" name="human[day]" id="human_day"><option value="">Day</option></select></div><div class="columns select"><select data-validetta="required" name="human[month]" id="human_month"><option value="">Month</option></select></div><div class="small-4 columns select"><select data-validetta="required" name="human[year]" id="human_year"><option value="">Year</option></select></div></div><input autocomplete="false" type="hidden" name="human[birthday]" id="human_birthday"></div>`;
        break;
        default:
          switch (e.mode){
            case '1':
            html += `<div class="form-item" data-preview-id="${e.id}"><label class="${e.required === 'true' ? 'f-required' : ''}">${e.label}</label><input placeholder="" autocomplete="false" data-validetta="required,regExp[full_name]" type="text" name="human[full_name]" id="human_full_name"></div>`;
            break;
            case '2':
            html += `<div class="form-item" data-preview-id="${e.id}"><label class="${e.required === 'true' ? 'f-required' : ''}">${e.label}</label><input placeholder="" autocomplete="false" data-validetta="required,regExp[full_name]" type="number" name="human[full_name]" id="human_full_name"></div>`;
            break;
            case '4':
            html += `<div class="form-item" data-preview-id="${e.id}"><label class="${e.required === 'true' ? 'f-required' : ''}">${e.label}</label><input placeholder="" autocomplete="false" data-validetta="required,regExp[full_name]" type="date" name="human[full_name]" id="human_full_name"></div>`;
            break;
            default:
            html += `<div class="form-item"><label class="${e.required === 'true' ? 'f-required' : ''}" for="${e.id}">${e.label}</label><div class="row collapse" id="country-picker"><div class="small-12 columns select"><select data-validetta="required" name="human[country]" id="human_country"><option value=""></option></select></div></div></div>`;
          }
      }
    })
    html += '<div class="submit-button"><button type="submit" class="fas"></button></div>'
    $("#mainframe").contents().find("form#test").html(html)
  }


}
