import { Controller } from "stimulus"

export default class extends Controller {

  initialize(){
    $.ajax({
      type: 'GET',
      url: 'reviews/render_summary',
      cache: false,
      complete: function() {
        $("body").removeClass("loading-page"); // REMOVE LOADER
        $('[data-toggle="tooltip"]').tooltip(); // INITIALIZE TOOLTIP
        $.fn.matchHeight._update(); // MATCH HEIGHT
        $('#review-cont').isotope({ // LAY OUT GRID
          filter: '*',
          animationOptions: {
              duration: 750,
              easing: 'linear',
              queue: false
          }
        });
      }
    })
  }


}
