import { Controller } from "stimulus"

export default class extends Controller {

  static targets = [
    "checkbox",
    "container"
  ]

  toggle(){
    if($(this.checkboxTarget).is(":checked")){
      // CHECKED
      this.showContainer();
      console.log('checked');
    } else {
      // NOT CHECKED
      this.hideContainer();
      console.log('not checked');
    }
  }

  showContainer(){
    $(this.containerTarget).slideDown(200);
  }

  hideContainer(){
    $(this.containerTarget).slideUp(200);
  }

  showTwitter(){
    $('#facebookframe').hide();
    $('#twitterframe').show();
  }

  showFacebook(){
    $('#twitterframe').hide();
    $('#facebookframe').show();
  }

}
