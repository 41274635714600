import { Controller } from "stimulus"
import Rails from "@rails/ujs";

export default class extends Controller {
  static targets = [
    "page",
    "next",
    "back",
    "sourceApiKey",
    "sourceOrg",
    "sourceNetwork",
    "sourceSsid",
    "targetApiKey",
    "targetOrg",
    "targetNetwork",
    "targetSsid"
  ]

  initialize() {
    // SET INITIAL PAGE AND COUNT
    this.showPage(0);
    this.pageCount = (this.pageTargets.length - 1);
  }

  async getTranslatedWords(title, text, confirmButton) {
    const result = await Weglot.translate({
      words: [
        { t: 1, w: title },
        { t: 1, w: text },
        { t: 1, w: confirmButton },
      ],
      languageTo: Weglot.getCurrentLang(),
    });
    return result;
  }

  back() {
    this.index = (this.index - 1);
    // CHECK IF BACK OR CANCEL
    if(this.index == -1){
      // CLOSE MODAL
      jQuery(this.element).modal("toggle");
    } else {
      // GO TO PREVIOUS PAGE
      this.showPage(this.index);
    };
  }

  showPage(index){
    this.index = index

    // HIDE ALL PAGES AND SHOW THE ACTIVE ONE
    jQuery(this.pageTargets).hide();
    jQuery(this.pageTargets[index]).show();

    // UNLOCK NEXT BUTTON
    jQuery(this.nextTarget).prop("disabled", false);
  }

  grabOrgs(event){
    const [data, status, xhr] = event.detail;
    console.log(data);

    // CHECK IF API CALL WAS SUCCESFUL, OR RESPOND WITH ERROR
    if(!('errors' in data)){
      // REMOVE EXISTING DROPDOWN ORGS TO AVOID DUPLICATES
      jQuery("select[name='meraki[org]'] option").remove();
      data.forEach(function (org, index) {
        // ADD ORGS FROM API VALIDATION RESPONSE
        jQuery("select[name='meraki[org]']").prepend('<option value="' + org["id"] + '">' + org["name"] + '</option>');
      });
      // TRANSFER VALUES FROM PREVIOUS STEPS FOR CREATE CALL
      jQuery(this.targetApiKeyTargets).val(jQuery(this.sourceApiKeyTarget).val());
      // SWITCH PAGE
      this.showPage(1);
    } else {
      // VALIDATION FAILED, RAISE ERROR
      if(status == "OK"){
        // API WORKS, DASHBOARD ERROR
        this.raiseError(data["errors"][0]);
      } else {
        // API ERROR, RAISE STANDARD
        this.raiseError("We could not connect to your dashboard, please try again or contact support.");
      }
    }
  }

  grabNetworks(event){
    const [data, status, xhr] = event.detail;
    console.log(data);

    // CHECK IF API CALL WAS SUCCESFUL, OR RESPOND WITH ERROR
    if(!('errors' in data)){
      // REMOVE EXISTING DROPDOWN ORGS TO AVOID DUPLICATES
      jQuery("select[name='meraki[network]'] option").remove();
      data.forEach(function (network, index) {
        // ADD ORGS FROM API VALIDATION RESPONSE
        jQuery("select[name='meraki[network]']").prepend('<option value="' + network["id"] + '">' + network["name"] + '</option>');
      });
      // TRANSFER VALUES FROM PREVIOUS STEPS FOR CREATE CALL
      jQuery(this.targetOrgTargets).val(jQuery(this.sourceOrgTarget).val());
      // SWITCH PAGE
      this.showPage(2);
    } else {
      // VALIDATION FAILED, RAISE ERROR
      if(status == "OK"){
        // API WORKS, DASHBOARD ERROR
        this.raiseError(data["errors"][0]);
      } else {
        // API ERROR, RAISE STANDARD
        this.raiseError("We could not get your networks, please try again or contact support.");
      }
    }
  }

  grabSsids(event){
    const [data, status, xhr] = event.detail;
    console.log(data);

    // CHECK IF API CALL WAS SUCCESFUL, OR RESPOND WITH ERROR
    if(!('errors' in data)){
      // REMOVE EXISTING DROPDOWN ORGS TO AVOID DUPLICATES
      jQuery("select[name='meraki[ssid]'] option").remove();
      data.forEach(function (ssid, index) {
        // ADD ORGS FROM API VALIDATION RESPONSE
        jQuery("select[name='meraki[ssid]']").prepend('<option value="' + ssid["number"] + '">' + ssid["name"] + '</option>');
      });
      // TRANSFER VALUES FROM PREVIOUS STEPS FOR CREATE CALL
      jQuery(this.targetNetworkTargets).val(jQuery(this.sourceNetworkTarget).val());
      // SWITCH PAGE
      this.showPage(3);
    } else {
      // VALIDATION FAILED, RAISE ERROR
      if(status == "OK"){
        // API WORKS, DASHBOARD ERROR
        this.raiseError(data["errors"][0]);
      } else {
        // API ERROR, RAISE STANDARD
        this.raiseError("We could not get your SSIDs, please try again or contact support.");
      }
    }
  }

  createIntegration(event){
    const [data, status, xhr] = event.detail;

    this.completed();
  }

  raiseError(error){
    toastr.error(error);

    jQuery('.btn-spinner').blur();
  }

  async completed(){
    jQuery(this.element).modal("hide");

    let title = 'Location activated';
    let text  = "Your WiFi login is now active on your Meraki network"
    let confirmButton = 'Continue';

    try {
      const data = await this.getTranslatedWords(title, text, confirmButton);

      if (data) {
        title = data[0]
        text  = data[1]
        confirmButton = data[2]
      }

      swal({
        title: title,
        text: text,
        type: 'success',
        confirmButtonText: confirmButton,
        confirmButtonClass: 'btn btn-primary waves-effect waves-light',
        buttonsStyling: false
      }).then(function () {
        Turbolinks.visit(window.location.href.substr(0, window.location.href.indexOf('#')));
      });
    } catch (e) {}
  }
}
