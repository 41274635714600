import { Controller } from "stimulus";
import Chart from 'chart.js/auto';

export default class ChartController extends Controller {

  connect() {
    this.setupChart();
  }

  setupChart() {
    const ctx = document.getElementById('spamChart');
    let spamScore = parseFloat(this.data.get('score'));
    if (spamScore > 8) {
      spamScore = 8;
    }
    const data1 = 12.5 * spamScore;
    const data2 = 100 - data1;
    const bgColor = this.calculateBackgroundColor(spamScore);

    new Chart(ctx, {
      type: 'doughnut',
      data: {
        labels: [],
        datasets: [{
          data: [data1, data2],
          backgroundColor: [bgColor, '#A3BED9'],
          borderWidth: 0,
          cutout: '70%',
          circumference: 180,
          rotation: 270
        }]
      },
      options: {
        events: [],
      }
    });
  }

  calculateBackgroundColor(spamScore) {
    return spamScore >= 5 ? '#FF3333' : '#0D9A51';
  }
}