import { Controller } from "stimulus"
//import Rails from "@rails/ujs";
import consumer from "channels/consumer"

export default class extends Controller {
  static targets = [
    "sends",
    "opens",
    "clicks",
    'bounces',
    'complaints',
    "is_sms",
    "openChart",
    "clickChart",
    'bounceChart',
    'complaintChart'
  ]

  connect(){
    this.subscription = consumer.subscriptions.create({ channel: "BlastLiveChannel", id: this.data.get("id")}, {
      connected: this._connected.bind(this),
      disconnected: this._disconnected.bind(this),
      received: this._received.bind(this)
    })
  }

  disconnect(){
    consumer.subscriptions.remove(this.subscription)
  }

  _connected(){

  }

  _disconnected(){

  }

  _received(data){
    let is_sms = this.is_smsTarget.value;
    let self  = this;
    $.ajax({
      type: 'GET',
      url: `/app/${document.querySelector('body').getAttribute('data-account-id')}/blast/live_data/${this.data.get("id")}`,
      cache: 'false',
      data: {months: document.querySelector('#timeframe_current').getAttribute('data-active')},
      success: function(res){
        if (is_sms == "true") {
          sends_vals = res.map(value => value.a)
          dates = res.map(value => value.y)

          sends = sends_vals.map(function(v, idx) {
            if ( !dates[idx].includes("-") ){
              return {
                meta: `${dates[idx]}:00` + "," + "Sends: " + v,
                value: v
              }
            } else {
              return {
                meta: dates[idx] + "," + "Sends: " + v,
                value: v
              }
            }

          })
          const newData = {
            labels: dates,
            series: [
              {
                data: sends,
                className: 'series-a',
                name: "Sends",
              },
            ]
          }
          chart.update(newData)
        } else {
          sends_vals = res.map(value => value.a)
          dates = res.map(value => value.y)
          opens_vals = res.map(value => value.b)
          clicks_vals = res.map(value => value.c)
          bounce_vals = res.map(value => value.d);
          complaint_vals = res.map(value => value.e);

          var sends = self.generateDataArray(dates, sends_vals, opens_vals, clicks_vals, bounce_vals, complaint_vals, sends_vals);
          var opens = self.generateDataArray(dates, sends_vals, opens_vals, clicks_vals, bounce_vals, complaint_vals, opens_vals);
          var clicks = self.generateDataArray(dates, sends_vals, opens_vals, clicks_vals, bounce_vals, complaint_vals, clicks_vals);
          var bounces = self.generateDataArray(dates, sends_vals, opens_vals, clicks_vals, bounce_vals, complaint_vals, bounce_vals);
          var complaints = self.generateDataArray(dates, sends_vals, opens_vals, clicks_vals, bounce_vals, complaint_vals, complaint_vals);

          const newData = {
            labels: dates,
            series: [
              {
                data: sends,
                className: 'series-a',
                name: "Sends",
              },
              {
                data: opens,
                className: 'series-b',
                name: "Opens",
              },
              {
                data: clicks,
                className: 'series-c',
                name: "Clicks",
              },
              {
                data: bounces,
                className: 'series-d',
                name: "Bounces",
              },
              {
                data: complaints,
                className: 'series-e',
                name: "Complaints",
              },
            ]
          }
          chart.update(newData)
        }
      }
    })

    if (data.mode == 6){
      if (this.is_smsTarget.value != "true") {
        const send_count = parseFloat(data.value)
        this.sendsTarget.innerText = send_count
        let open_ratio = (self.parseValueToFloat(this.opensTarget.innerText) / send_count) * 100
        jQuery(this.openChartTarget).val(open_ratio).trigger('change')
      }

    } else if (data.mode == 8){
      const open_count = parseFloat(data.value)
      this.opensTarget.innerText = open_count

      let open_ratio = (open_count / self.parseValueToFloat(this.sendsTarget.innerText)) * 100
      let click_ratio = (self.parseValueToFloat(this.clicksTarget.innerText) / open_count) * 100

      jQuery(this.openChartTarget).val(open_ratio).trigger('change')
      jQuery(this.clickChartTarget).val(click_ratio).trigger('change')

    } else if (data.mode == 10){
      const click_count = parseFloat(data.value)
      this.clicksTarget.innerText = click_count

      let click_ratio = (click_count / self.parseValueToFloat(this.opensTarget.innerText)) * 100

      jQuery(this.clickChartTarget).val(click_ratio).trigger('change')

    } else if (data.mode == 12) {
      const bounce_count = parseFloat(data.value)
      this.bouncesTarget.innerText = bounce_count
      let bounce_ratio = (bounce_count / self.parseValueToFloat(this.sendsTarget.innerText)) * 100
      jQuery(this.bounceChartTarget).val(bounce_ratio).trigger('change')

    } else {
      const complaint_count = parseFloat(data.value)
      this.complaintsTarget.innerText = complaint_count
      console.log(self.parseValueToFloat(this.sendsTarget.innerText))
      let complaint_ratio = (complaint_count / self.parseValueToFloat(this.sendsTarget.innerText)) * 100
      jQuery(this.complaintChartTarget).val(complaint_ratio).trigger('change')
    }
  }

  parseValueToFloat(value){
    return parseFloat(value.replace(/,/g, ''))
  }

  generateDataArray(dates, sends_vals, opens_vals, clicks_vals, bounce_vals, complaint_vals, dataType) {
    return dataType.map(function (v, idx) {
      const meta = `${dates[idx].includes("-") ? dates[idx] : dates[idx] + ':00'}, ` +
        `Sends: ${sends_vals[idx]}, Opens: ${opens_vals[idx]}, Clicks: ${clicks_vals[idx]}, ` +
        `Bounces: ${bounce_vals[idx]}, Complaints: ${complaint_vals[idx]}`;

      return { meta, value: v };
    });
  }
}
