import { Controller } from "stimulus"
//import Rails from "@rails/ujs";
import consumer from "channels/consumer"

export default class extends Controller {
  static targets = [
    "sends"
  ]

  connect(){
    this.subscription = consumer.subscriptions.create({ channel: "DashboardChannel", id: this.data.get("id")}, {
      connected: this._connected.bind(this),
      disconnected: this._disconnected.bind(this),
      received: this._received.bind(this)
    })
  }

  disconnect(){
    consumer.subscriptions.remove(this.subscription)
  }

  _connected(){

  }

  _disconnected(){

  }

  _received(data){
    const guest_data = JSON.parse(data)
    const params = JSON.parse( jQuery("#filter-summary").attr('data-filters') )
    //const guest_created_date = new Date(guest_data.created_at)

    console.log(guest_data)

    $.ajax({
      url: "guests/check_visit.js",
      method: "GET",
      cache: false,
      data: {params_array: params, guest_id: guest_data.id},
      success: function(res){
        const new_guest = (guest_data.first_visit_date === guest_data.last_visit_date ? true : false)
        if (new_guest === true) {
          let new_value = new_guests[new_guests.length - 1].value + 1
          new_guests[new_guests.length - 1].value = new_value
          let meta = new_guests[new_guests.length - 1].meta.split(',')
          meta[1] = `New Guests: ${new_value}`
          new_guests[new_guests.length - 1].meta = meta.join(',')
          repeat_guests[repeat_guests.length - 1].meta = meta.join(',')
          const new_data = {
            labels: dates,
            series: [new_guests, repeat_guests]
          }
          chart.update(new_data)

        } else {
          let new_value = repeat_guests[repeat_guests.length - 1].value + 1
          repeat_guests[repeat_guests.length - 1].value = new_value
          let meta = repeat_guests[repeat_guests.length - 1].meta.split(',')
          meta[2] = `Repeat Guests: ${new_value}`
          new_guests[new_guests.length - 1].meta = meta.join(',')
          repeat_guests[repeat_guests.length - 1].meta = meta.join(',')
          const new_data = {
            labels: dates,
            series: [new_guests, repeat_guests]
          }
          chart.update(new_data)
        }
      }
    })
  }


}
