import { Controller } from "stimulus"
import Rails from "@rails/ujs";

export default class extends Controller {

  static targets = [
	  "sideBar",
	  "sticky",
	  "content",
	  "section"
  ]

  connect() {
	  if($('.article_content').length != 0 ){

	  	this.sideBarStart = $(this.sideBarTarget).offset().top;
	  	this.updateVariables();
	  };

	  this.connectHeadlinesToTable();
  }

  updateVariables() {
  	// GET NECESSARY POSITIONING INTS

  	this.currentScroll = $(window).scrollTop();

    this.contentStart = $(this.contentTarget).offset().top;
    this.contentHeight = $(this.contentTarget).height();
    this.contentEnd = (this.contentStart + this.contentHeight);

    this.sideBarRightOffset = ($(window).width() - ($(this.sideBarTarget).offset().left + $(this.sideBarTarget).outerWidth()));
    this.sideBarHeight = $(this.sideBarTarget).height();
    this.sideBarWidth = $(this.sideBarTarget).outerWidth();

    this.stickyHeight = $(this.stickyTarget).height();

    // SET SIDEBAR HEIGHT
    $(this.sideBarTarget).height(this.contentHeight);
  }

  updatePosition(){
  	// UPDATES POSITION OF THE STICKY SECTION

  	this.updateVariables();
	  $(this.stickyTarget).attr('style', 'width:' + this.sideBarWidth + 'px;right:' + (this.sideBarRightOffset == 0 ? 10 : this.sideBarRightOffset) + 'px;');

	  if (this.currentScroll < this.sideBarStart) {
	    // START, SHOW TOP
	    $(this.stickyTarget).removeClass('fixed-bottom');
	    $(this.stickyTarget).removeClass('fixed-scroll');
	  } else if ((this.currentScroll > (this.sideBarStart - 40)) && (this.currentScroll < (this.contentEnd - this.stickyHeight))) {
	    // MIDDLE, FIX SIDEBAR
	    $(this.stickyTarget).removeClass('fixed-bottom');
	    $(this.stickyTarget).addClass('fixed-scroll');

	    // HIGHLIGHT CURRENT HEADLINE SCOPE
	    this.detectNearestContent(this.currentScroll);
	  } else if (this.currentScroll > (this.contentEnd - this.stickyHeight)) {
	    // END, SHOW BOTTOM
	    $(this.stickyTarget).addClass('fixed-bottom');
	    $(this.stickyTarget).removeClass('fixed-scroll');
	  }
  }

  moveToHeadline(event){
  	// SCROLLS PAGE TO HEADLINE AND SETS LI AS ACTIVE

  	// window.location.hash = $(event.target).attr("data-headline-id");
    var headline = $('h2:contains("' + $(event.target).text() + '")');
    $('html,body').animate({scrollTop: ($(headline).offset().top - 40)}, 500);
  }

  detectNearestContent(currentScroll){
  	// FINDS AND HIGHLIGHTS NEAREST HEADLINE IF APPLICABLE

  	var closestHeadline = false;
  	$(".article_content h2").each(function(){
  		if(($(this).attr("id")) && (currentScroll > ($(this).offset().top) - 50)){
  			closestHeadline = $(this);
  		}
  	});

  	if(closestHeadline != false){
  		$('.table-of-contents li').removeClass("active");
			$('.table-of-contents li[data-headline-id="' + $(closestHeadline).attr("id") + '"]').addClass("active");
  	} else {
  		$('.table-of-contents li').removeClass("active");
  	}
  }

  connectHeadlinesToTable(){
  	$('.table-of-contents li').each(function(){
  		$(this).attr("data-headline-id", $('h2:contains("' + $(this).text() + '")').attr("id"));
  	});
  };
}