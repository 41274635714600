// app/javascript/controllers/banner_controller.js

import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['banner'];

  close(event) {
    event.preventDefault();

    const contentScope = this.data.get("contentScope");

    if (contentScope === 'townsquare') {
      var beamboxBar = document.querySelector('.beambox-bar');

      this.bannerTarget.style.transform = 'translateY(-100%)';
      this.bannerTarget.remove();

      const banners = document.querySelectorAll('.banner');

      if (banners.length === 0) {
        beamboxBar.style.marginTop = 0;
      }
    }

    else if (contentScope === 'website') {
      var mobileNav = document.querySelector('.title-bar');
      var mainSection = document.getElementById('main-section');

      this.bannerTarget.style.transform = 'translateY(-100%)';
      this.bannerTarget.remove();

      const banners = document.querySelectorAll('.banner');

      if (banners.length === 0) {
        mobileNav.style.marginTop = 0;
        mainSection.style.marginTop = 0;
      }
    } else {
      var topNav = document.getElementById('topnav');
      var wrapper = document.querySelector('.wrapper');
      var weglot = document.querySelector('.country-selector');

      this.bannerTarget.style.transform = 'translateY(-100%)';
      this.bannerTarget.remove();

      const banners = document.querySelectorAll('.banner');

      if (banners.length === 0) {
        const marginTop = window.innerWidth <= 768 ? '130px' : '90px';
        wrapper.style.marginTop = marginTop;
        if (window.innerWidth <= 768) {
          weglot.style.top = `${weglot.getBoundingClientRect().top - 50}px`
        }
      }
    }
  }
}
