import { Controller } from "stimulus"
import Rails from "@rails/ujs";

export default class extends Controller {

  static targets = [
	  "sideBar",
	  "sticky",
	  "content"
  ]

  connect() {
	  if($('.article_content').length != 0 ){
	  	this.sideBarStart = $(this.sideBarTarget).offset().top;
	  	this.updateVariables();
	  };
  }

  updateVariables() {
  	// GET NECESSARY POSITIONING INTS

  	this.currentScroll = $(window).scrollTop();

    this.contentStart = $(this.contentTarget).offset().top;
    this.contentHeight = $(this.contentTarget).height();
    this.contentEnd = (this.contentStart + this.contentHeight);

    this.sideBarRightOffset = ($(window).width() - ($(this.sideBarTarget).offset().left + $(this.sideBarTarget).outerWidth()));
    this.sideBarHeight = $(this.sideBarTarget).height();
    this.sideBarWidth = $(this.sideBarTarget).outerWidth();

    this.stickyHeight = $(this.stickyTarget).height();

    // SET SIDEBAR HEIGHT
    $(this.sideBarTarget).height(this.contentHeight);
  }

  updatePosition(){
  	// UPDATES POSITION OF THE STICKY SECTION

  	this.updateVariables();
	  $(this.stickyTarget).attr('style', 'width:' + this.sideBarWidth + 'px;right:' + (this.sideBarRightOffset == 0 ? 10 : this.sideBarRightOffset) + 'px;');

	  if (this.currentScroll < this.sideBarStart) {
	    // START, SHOW TOP
	    $(this.stickyTarget).removeClass('fixed-bottom');
	    $(this.stickyTarget).removeClass('fixed-scroll');
	  } else if ((this.currentScroll > (this.sideBarStart - 40)) && (this.currentScroll < (this.contentEnd - this.stickyHeight))) {
	    // MIDDLE, FIX SIDEBAR
	    $(this.stickyTarget).removeClass('fixed-bottom');
	    $(this.stickyTarget).addClass('fixed-scroll');
	  } else if (this.currentScroll > (this.contentEnd - this.stickyHeight)) {
	    // END, SHOW BOTTOM
	    $(this.stickyTarget).addClass('fixed-bottom');
	    $(this.stickyTarget).removeClass('fixed-scroll');
	  }
  }

}