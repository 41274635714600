import { Controller } from "stimulus"
import Rails from "@rails/ujs";

let swalConfirmed = false

export default class extends Controller {
  static targets = []

  initialize() {}

  removeTeamate(event){
    if(!swalConfirmed){
      event.stopPropagation()
      event.preventDefault()
      var id = event.currentTarget.getAttribute('data-id')

      Weglot.translate({
        'words': [
          { "t": 1, "w": "Are you sure?" },
          { "t": 1, "w": "Removing cannot be undone" },
          { "t": 1, "w": "Yes, remove this teammate!" },
          { "t": 1, "w": "Cancel" }
        ],
        'languageTo': Weglot.getCurrentLang()
      }, function(data) {
        const title = data[0];
        const text = data[1];
        const confirmButton = data[2];
        const cancelButton = data[3];

        swal({
          title: title,
          text: text,
          type: 'warning',
          showCancelButton: true,
          confirmButtonText: confirmButton,
          cancelButtonText: cancelButton,
          confirmButtonClass: 'btn btn-lg btn-danger waves-effect waves-light m-t-20',
          cancelButtonClass: 'btn btn-lg btn-transparent waves-effect waves-light m-l-10 m-t-20',
          buttonsStyling: false
        }).then(function () {
          swalConfirmed = true;
          $(".remove-trigger-" + id).trigger("click");
        }, function (dismiss) {
            if (dismiss === 'cancel') {
              // window.location.href = link;
            }
        });
      });
    }
  }

  makeAccountOwner(event){
    if(!swalConfirmed){
      event.stopPropagation()
      event.preventDefault()
      var id = event.currentTarget.getAttribute("data-id")

      Weglot.translate({
        'words': [
          { "t": 1, "w": "Are you sure?" },
          { "t": 1, "w": "You will no longer have control of this account" },
          { "t": 1, "w": "Yes, change account owner!" },
          { "t": 1, "w": "Cancel" }
        ],
        'languageTo': Weglot.getCurrentLang()
      }, function(data) {
        const title = data[0];
        const text = data[1];
        const confirmButton = data[2];
        const cancelButton = data[3];

        swal({
          title: title,
          text: text,
          type: 'info',
          showCancelButton: true,
          confirmButtonText: confirmButton,
          cancelButtonText: cancelButton,
          confirmButtonClass: 'btn btn-lg btn-primary waves-effect waves-light m-t-20',
          cancelButtonClass: 'btn btn-lg btn-transparent waves-effect waves-light m-l-10 m-t-20',
          buttonsStyling: false
        }).then(function () {
          swalConfirmed = true;
          $(".make-owner-trigger-" + id).trigger("click");
        }, function (dismiss) {
            if (dismiss === 'cancel') {
              // window.location.href = link;
            }
        });
      });
    }
  }
}
