import { Controller } from "stimulus"
import Rails from "@rails/ujs";

export default class extends Controller {
  static targets = []

  connect(){
    //this.setGrid();

    // UPDATE MATCH HEIGHT IF REVIEWS PRESENT
    if($('#review-cont').length > 1){
      $.fn.matchHeight._update();
    }
  }

  async getTranslatedWords(title, text, confirmButton, cancelButton) {
    const result = await Weglot.translate({
      words: [
        { t: 1, w: title },
        { t: 1, w: text },
        { t: 1, w: confirmButton },
        { t: 1, w: cancelButton },
      ],
      languageTo: Weglot.getCurrentLang(),
    });
    return result;
  }

  async archiveReview(event){
    event.stopPropagation()
    event.preventDefault()
    const id = event.currentTarget.getAttribute('data-id')

    let title = 'Are you sure?';
    let text  = 'Archiving cannot be undone';
    let confirmButton = 'Yes, archive it!';
    let cancelButton  = 'Cancel';

    try {
      const data = await this.getTranslatedWords(title, text, confirmButton, cancelButton);

      if (data) {
        title = data[0]
        text  = data[1]
        confirmButton = data[2]
        cancelButton  = data[3]
      }

      swal({
          title: title,
          text: text,
          type: 'warning',
          showCancelButton: true,
          confirmButtonText: confirmButton,
          allowOutsideClick: true,
          cancelButtonText: cancelButton,
          confirmButtonClass: 'btn btn-lg btn-danger waves-effect waves-light m-t-20',
          cancelButtonClass: 'btn btn-lg btn-transparent waves-effect waves-light m-l-10 m-t-20',
          buttonsStyling: false
      }).then(function () {
          $.ajax({
            url: ('review/archive/' + id),
            type: 'DELETE',
            success: function(result) {
              $("#review-" + id).remove();
              $('#review-cont').isotope({
                filter: '*',
                animationOptions: {
                    duration: 750,
                    easing: 'linear',
                    queue: false
                }
              });
            }
          });
      }, function (dismiss) {
          if (dismiss === 'cancel') {
            // window.location.href = link;
          }
      })
    } catch (e) {}
  }

  setGrid(){
    // SET DYNAMIC GRID
    $('#review-cont').isotope({
      filter: '*',
      animationOptions: {
          duration: 750,
          easing: 'linear',
          queue: false
      }
    });
  }
}
