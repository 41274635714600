import { Controller } from "stimulus"
import Rails from "@rails/ujs";

export default class extends Controller {
  static targets = [
    "html"
  ]

  initialize(){

    $.ajax({
				url: this.htmlTarget.getAttribute("data-url"),
				type: 'GET',
				cache: false,
				data: this.htmlTarget.getAttribute("data-params"),
				success: function(res) {
					console.log("success");
					$.fn.matchHeight._update();

					$(function(){
						let height = $('.actual-content').outerHeight();
						$('.human-bar').height(height);
						$( ".human-bar .widget-user:gt(" + Math.floor((height / 105) - 1) + ")").remove();
					});

					$('[data-plugin="knob"]').each(function(idx, obj) {
            $(this).knob({
              'format' : function(value) {
                return value + '%';
              }
            });
					});
				}
		}); // render dashboard partial

  }



}
