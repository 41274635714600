import { Controller } from "stimulus"
import Rails from "@rails/ujs";

export default class extends Controller {

  connect() {
    console.log('QR Codes Initialized...');

    $('form').validetta({
      bubblePosition: 'bottom',
      realTime: true,
    });

    this.host   = window.BASE_URL != undefined ? window.BASE_URL : "https://beambox.com"
    this.userID = $("body").attr("data-account-id");
    this.user   = $("body").attr("data-account");
  }

  generateFormsQR(event) {
    event.preventDefault();

    const formKey = event.currentTarget.dataset.key;
    const formId = event.currentTarget.getAttribute('data-form-id');

    $.ajax({
      method: 'POST',
      url: `/app/${this.userID}/qr_codes`,
      data: {
        qr_code: {
          name: event.currentTarget.name,
          meta_data: {
            sourceable_type: 'Form',
            sourceable_id: formId,
          },
          qr_type: event.currentTarget.dataset.type,
          code: `/${this.user}/forms/${formKey}`,
        }
      }
    });
  }

  generateReviewQR(event) {
    event.preventDefault();

    const selectedLocationElem = document.getElementById('review_location_id').selectedOptions[0];
    const locationId = selectedLocationElem.value;
    const locationName = selectedLocationElem.innerText;

    if (locationName !== "Select Location") {
      $.ajax({
        method: 'POST',
        url: `/app/${this.userID}/qr_codes`,
        data: {
          qr_code: {
            name: locationName,
            meta_data: {
              sourceable_type: 'Location',
              sourceable_id: locationId,
            },
            qr_type: event.currentTarget.name,
            code: `/${this.user}/feedback/${locationId}`,
          }
        }
      });
    } else {
      toastr.error('Please select a location first');
    }
  }

  generateWifiQR(event) {
    event.preventDefault();

    const button = event.currentTarget;

    const ssid         = button.getAttribute('data-ssid');
    const encryption   = "nopass";
    const locationName = button.getAttribute('data-location');
    const locationId = button.getAttribute('data-location-id');

    $.ajax({
      method: 'POST',
      url: `/app/${this.userID}/qr_codes`,
      data: {
        qr_code: {
          name: locationName,
          meta_data: {
            sourceable_type: 'Location',
            sourceable_id: locationId,
          },
          qr_type: button.name,
          code: "WIFI:S:" + ssid + ";T:" + encryption + ";P:;",
        }
      }
    });
  }

  downloadPNG(event) {
    event.preventDefault();

    const qr_code = event.currentTarget.name

    const qrCodeElement = document.getElementById(qr_code);
    const qrCodeURL = qrCodeElement.getAttribute("src");

    const link = document.createElement("a");

    link.href = qrCodeURL;
    link.download = `${qr_code}.png`;
    link.click();
  }

  printQRCode(event) {
    event.preventDefault();

    const qrCodeElement = document.getElementById(event.currentTarget.name);
    const qrCodeURL = qrCodeElement.getAttribute("src");

    const image = new Image();
    image.src = qrCodeURL;

    const printWindow = window.open("", "_blank");

    printWindow.document.write(image.outerHTML);
    printWindow.document.close();
    printWindow.print();
  }

  addForm() {
    const selectedForm = document.getElementById('form-select').value;

    if (selectedForm !== "Select Form") {
      var formUpdatePath = "/app/" + $("body").attr("data-account-id") + `/forms/${selectedForm}/update.js`;

      $.ajax({
        url: formUpdatePath,
        method: 'PATCH',
        data: { embeddable_form: { setup: true } },
        success: function(response) {
          location.reload();

          toastr.success("Your embeddable form has been added");
        },
        error: function(xhr, status, error) {
          toastr.error("Something went wrong!");
        }
      });
    } else {
      toastr.error('Please select a form first');
    }
  }
}
