import { Controller } from "stimulus"

export default class extends Controller {

  static targets = [
    "menu",
    "mask"
  ]

  initialize(){
    this.menu_displayed = false
    this.should_persist = false
  }

  open(){
    // console.log("working")
    if (!this.menu_displayed){
      this.menuTarget.style.opacity = 1
      this.menuTarget.style.visibility = 'visible'
      this.menu_displayed = true
    }
  }

  close(){
    if (this.menu_displayed){
      this.menuTarget.style.opacity = 0
      this.menuTarget.style.visibility = 'hidden'
      this.menu_displayed = false
    }
  }

  persist(){
    if (this.should_persist){
      this.maskTarget.style.display = "none"
      this.should_persist = false
    } else {
      this.maskTarget.style.display = "block"
      this.should_persist = true
    }
  }
}
