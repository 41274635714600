import { Controller } from "stimulus"

export default class extends Controller {
  async getTranslatedWords(title, text, confirmButton, cancelButton) {
    const result = await Weglot.translate({
      words: [
        { t: 1, w: title },
        { t: 1, w: text },
        { t: 1, w: confirmButton },
        { t: 1, w: cancelButton },
      ],
      languageTo: Weglot.getCurrentLang(),
    });
    return result;
  }

  async addLocation(event) {
    event.stopPropagation();
    event.preventDefault();

    let title = 'Are you sure?';
    let text  = 'By adding this new location a Plug & Play Beambox router will be sent to you by mail';
    let confirmButton = 'Yes, add location';
    let cancelButton  = 'Cancel';

    try {
      const translatedWords = await this.getTranslatedWords(title, text, confirmButton, cancelButton);

      if (translatedWords.length == 4) {
        [title, text, confirmButton, cancelButton] = translatedWords;
      }

      swal({
        title: title,
        text: text,
        type: 'warning',
        showCancelButton: true,
        confirmButtonClass: 'btn btn-lg btn-primary waves-effect waves-light m-t-20',
        cancelButtonClass: 'btn btn-lg btn-transparent waves-effect waves-light m-l-10 m-t-20',
        confirmButtonText: confirmButton,
        cancelButtonText: cancelButton,
        buttonsStyling: false
      }).then((result) => {
        if (result) {
          jQuery('#add-delivery-address-modal').modal('toggle');
        }
      })
      .catch(() => {
        jQuery('#new-location-modal').modal('toggle');
      });
    } catch (error) {}
  }
}
