import { Controller } from "stimulus";
import EmblaCarousel from 'embla-carousel';

export default class HardwareController extends Controller {
  connect() {
    const addTogglePrevNextBtnsActive = (emblaApi, prevBtn, nextBtn) => {
      const togglePrevNextBtnsState = () => {
        if (emblaApi.canScrollPrev()) prevBtn.removeAttribute('disabled');
        else prevBtn.setAttribute('disabled', 'disabled');

        if (emblaApi.canScrollNext()) nextBtn.removeAttribute('disabled');
        else nextBtn.setAttribute('disabled', 'disabled');
      };

      emblaApi
        .on('select', togglePrevNextBtnsState)
        .on('init', togglePrevNextBtnsState)
        .on('reInit', togglePrevNextBtnsState);

      return () => {
        prevBtn.removeAttribute('disabled');
        nextBtn.removeAttribute('disabled');
      };
    };

    function addPrevNextBtnsClickHandlers(emblaApi, prevBtn, nextBtn) {
      const scrollPrev = () => emblaApi.scrollPrev();
      const scrollNext = () => emblaApi.scrollNext();
      prevBtn.addEventListener('click', scrollPrev, false);
      nextBtn.addEventListener('click', scrollNext, false);

      const removeTogglePrevNextBtnsActive = addTogglePrevNextBtnsActive(
        emblaApi,
        prevBtn,
        nextBtn
      );

      return () => {
        removeTogglePrevNextBtnsActive();
        prevBtn.removeEventListener('click', scrollPrev, false);
        nextBtn.removeEventListener('click', scrollNext, false);
      };
    }
    
    // Grab wrapper nodes
    const slideNode = document.querySelector('.hardwareFlow-slider');

    // Grab button nodes
    const prevBtn = slideNode.querySelector('.embla__prev');
    const nextBtn = slideNode.querySelector('.embla__next');

    // Initialize the carousel
    const embla = EmblaCarousel(slideNode);

    const flowOptions = {
      active: true,
    }

    const emblaApi = EmblaCarousel(slideNode, flowOptions);

    const removePrevNextBtnsClickHandlers = addPrevNextBtnsClickHandlers(
      emblaApi,
      prevBtn,
      nextBtn
    );

    emblaApi.on('destroy', removePrevNextBtnsClickHandlers);

    
  }
}
