import { Controller } from "stimulus"
import heic2any from 'heic2any';


export default class extends Controller {

  static targets = [
    "position_option",
    "position_option_button",
    "edge_option",
    "edge_option_button",
    "alignment_option",
    "alignment_option_button",
    "mode_option_button",
    "mode_option",
    "logo_picker",
    "background_picker"
  ]

  set_font_option(selectElement) {
    this.prodEnv = selectElement.getAttribute("data-prod-env");
    this.baseUrl = `https://${this.prodEnv === 'true' ? 'wifiuploads' : 'beambox-dev'}.s3.amazonaws.com/`;

    const dataFontValue = selectElement.getAttribute('data-font');

    Array.from(selectElement.options).forEach(option => {
      let updatedValue = option.value;

      if (updatedValue.includes(this.baseUrl)) {
        updatedValue = updatedValue.replace(this.baseUrl, '');
      }

      updatedValue = updatedValue.replace(/%20/g, ' ');

      option.value = updatedValue;

      if (updatedValue === dataFontValue) {
        option.selected = true;
      }
    });
  }

  initialize(){
    jQuery('#mainframe').attr("style", "overflow:hidden;min-height:" + (jQuery(window).height() - jQuery('#topnav').height()) + "px;")
    jQuery(window).on('resize', function(){
      jQuery('#mainframe').attr("style", "overflow:hidden;min-height:" + (jQuery(window).height() - jQuery('#topnav').height()) + "px;")
    })

    const selectElement = document.getElementById('font_selector');
    this.set_font_option(selectElement);

    const bodySelectElement = document.getElementById('body_font_selector');
    this.set_font_option(bodySelectElement);

    const buttonSelectElement = document.getElementById('btn_font_selector');
    this.set_font_option(buttonSelectElement);

    const formSelectElement = document.getElementById('input_font_selector');
    this.set_font_option(formSelectElement);

    this.business_name = document.getElementById("user_businessname").value
    this.logo_url = document.getElementById("logo-picker").getAttribute("data-default-file")
    this.logo_size = document.getElementById("user_logo_size").value

    if (document.getElementById("user_logo_position_left").checked){
      this.logo_position = "left"
    } else if (document.getElementById("user_logo_position_right").checked) {
      this.logo_position = "right"
    } else {
      this.logo_position = "center"
    }

    this.container_colour = document.getElementById("user_bgrgba").value
    this.container_opacity = (document.getElementById("user_bgopacity").value / 100).toFixed(2)

    if (document.getElementById("user_container_edges_straight").checked){
      this.container_edges = "straight"
    } else if (document.getElementById("user_container_edges_rounded").checked){
      this.container_edges = "rounded"
    } else {
      this.container_edges = "circular"
    }

    this.font = $("#font_selector option:selected")[0].innerText;
    this.font_path = document.getElementById("font_selector").value
    this.text_colour = document.getElementById("heading_text_color").value
    this.cta_bg_color = document.getElementById("user_cta_bg").value
    this.accent_colour = document.getElementById("user_secondaryhex").value
    this.mode = (document.getElementById("user_background_mode_image").checked ? "image" : "colour")
    this.background_image = document.getElementById("background-picker").getAttribute("data-default-file")
    this.background_colour = document.getElementById("user_background_color").value

    jQuery('#picker').colpick({
      layout:'rgbhex',
      submit:0,
      color: document.getElementById('user_bghex').value,
      colorScheme:'light',
      onChange:function(hsb,hex,rgb,el,bySetColor) {
        document.getElementById('picker').setAttribute('style', `background:#${hex}`)
        var rgbcompile = "rgba(" + rgb.r + "," + rgb.g + "," + rgb.b + ",1)"
        document.getElementById('user_bghex').setAttribute('value', hex)
        document.getElementById('user_bgrgba').setAttribute('value', rgbcompile)
        const evt = new Event('change')
        document.getElementById('user_bgrgba').dispatchEvent(evt)
        // Fill the text box just if the color was set using the picker, and not the colpickSetColor function.
        if(!bySetColor) jQuery(el).val(hex);
      }
    }).keyup(function(){
      jQuery(this).colpickSetColor(this.value);
    });

    jQuery('#text-picker').colpick({
      layout:'rgbhex',
      submit:0,
      color: this.text_colour,
      colorScheme:'light',
      onChange:function(hsb,hex,rgb,el,bySetColor) {
        jQuery('#text-picker').css('background', "#" + hex)
        document.getElementById('heading_text_color').setAttribute("value", hex)
        const evt = new Event('change')
        document.getElementById('heading_text_color').dispatchEvent(evt)
        // Fill the text box just if the color was set using the picker, and not the colpickSetColor function.
        if(!bySetColor) jQuery(el).val(hex)
      }
    }).keyup(function(){
      jQuery(this).colpickSetColor(this.value)
    })

    jQuery('#body-text-picker').colpick({
      layout:'rgbhex',
      submit:0,
      color: document.getElementById('user_body_color').value,
      colorScheme:'light',
      onChange:function(hsb,hex,rgb,el,bySetColor) {
        jQuery('#body-text-picker').css('background', "#" + hex)
        document.getElementById('user_body_color').setAttribute("value", hex)
        const evt = new Event('change')
        document.getElementById('user_body_color').dispatchEvent(evt)
        // Fill the text box just if the color was set using the picker, and not the colpickSetColor function.
        if(!bySetColor) jQuery(el).val(hex)
      }
    }).keyup(function(){
      jQuery(this).colpickSetColor(this.value)
    })

    jQuery('#btn-bg-picker').colpick({
      layout:'rgbhex',
      submit:0,
      color: document.getElementById('user_btn_hex').value,
      colorScheme:'light',
      onChange:function(hsb,hex,rgb,el,bySetColor) {
        jQuery('#btn-bg-picker').css('background', "#" + hex)
        var rgbcompile = "rgba(" + rgb.r + "," + rgb.g + "," + rgb.b + ",1)"
        document.getElementById("user_btn_hex").setAttribute("value", hex)
        document.getElementById("user_btn_rgba").setAttribute("value", rgbcompile)
        const evt = new Event('change')
        document.getElementById('user_btn_hex').dispatchEvent(evt)
        // Fill the text box just if the color was set using the picker, and not the colpickSetColor function.
        if(!bySetColor) jQuery(el).val(hex)
      }
    }).keyup(function(){
      jQuery(this).colpickSetColor(this.value)
    })

    jQuery('#btn-text-picker').colpick({
      layout:'rgbhex',
      submit:0,
      color: document.getElementById('user_btn_text_color').value,
      colorScheme:'light',
      onChange:function(hsb,hex,rgb,el,bySetColor) {
        jQuery('#btn-text-picker').css('background', "#" + hex)
        document.getElementById('user_btn_text_color').setAttribute("value", hex)
        const evt = new Event('change')
        document.getElementById('user_btn_text_color').dispatchEvent(evt)
        // Fill the text box just if the color was set using the picker, and not the colpickSetColor function.
        if(!bySetColor) jQuery(el).val(hex)
      }
    }).keyup(function(){
      jQuery(this).colpickSetColor(this.value)
    })

    jQuery('#input-text-picker').colpick({
      layout:'rgbhex',
      submit:0,
      color: document.getElementById('form_input_color').value,
      colorScheme:'light',
      onChange:function(hsb,hex,rgb,el,bySetColor) {
        jQuery('#input-text-picker').css('background', "#" + hex)
        document.getElementById('form_input_color').setAttribute("value", hex)
        const evt = new Event('change')
        document.getElementById('form_input_color').dispatchEvent(evt)
        // Fill the text box just if the color was set using the picker, and not the colpickSetColor function.
        if(!bySetColor) jQuery(el).val(hex)
      }
    }).keyup(function(){
      jQuery(this).colpickSetColor(this.value)
    })

    jQuery('#cta-background-picker').colpick({
      layout:'rgbhex',
      submit:0,
      color: this.cta_bg_color,
      colorScheme:'light',
      onChange:function(hsb,hex,rgb,el,bySetColor) {
        jQuery('#cta-background-picker').css('background', "#" + hex)
        document.getElementById('user_cta_bg').setAttribute("value", hex)
        const evt = new Event('change')
        document.getElementById('user_cta_bg').dispatchEvent(evt)
        // Fill the text box just if the color was set using the picker, and not the colpickSetColor function.
        if(!bySetColor) jQuery(el).val(hex)
      }
    }).keyup(function(){
      jQuery(this).colpickSetColor(this.value)
    })

    jQuery('#secondary-picker').colpick({
      layout:'rgbhex',
      submit:0,
      color: document.getElementById('user_secondaryhex').value,
      colorScheme:'light',
      onChange:function(hsb,hex,rgb,el,bySetColor) {
        jQuery('#secondary-picker').css('background', "#" + hex)
        var rgbcompile = "rgba(" + rgb.r + "," + rgb.g + "," + rgb.b + ",1)"
        document.getElementById("user_secondaryhex").setAttribute("value", hex)
        document.getElementById("user_secondaryrgba").setAttribute("value", rgbcompile)
        const evt = new Event('change')
        document.getElementById('user_secondaryhex').dispatchEvent(evt)
        // Fill the text box just if the color was set using the picker, and not the colpickSetColor function.
        if(!bySetColor) jQuery(el).val(hex)
      }
    }).keyup(function(){
      jQuery(this).colpickSetColor(this.value)
    })

    jQuery('#background-color-picker').colpick({
      layout:'rgbhex',
      submit:0,
      color: document.getElementById('user_background_color').value,
      colorScheme:'light',
      onChange:function(hsb,hex,rgb,el,bySetColor) {
        document.getElementById('background-color-picker').setAttribute('style', `background:#${hex}`)
        //var rgbcompile = "rgba(" + rgb.r + "," + rgb.g + "," + rgb.b + ",1)"

        document.getElementById('user_background_color').setAttribute('value', `#${hex}`)
        const evt = new Event('change')
        document.getElementById('user_background_color').dispatchEvent(evt)
        // Fill the text box just if the color was set using the picker, and not the colpickSetColor function.
        if(!bySetColor) jQuery(el).val(hex)
      }
    }).keyup(function(){
      jQuery(this).colpickSetColor(this.value)
    })
  }

  set_font_option(selectElement) {
    this.prodEnv = selectElement.getAttribute("data-prod-env");
    this.baseUrl = `https://${this.prodEnv === 'true' ? 'wifiuploads' : 'beambox-dev'}.s3.amazonaws.com/`;

    const dataFontValue = selectElement.getAttribute('data-font');

    Array.from(selectElement.options).forEach(option => {
      let updatedValue = option.value;

      if (updatedValue.includes(this.baseUrl)) {
        updatedValue = updatedValue.replace(this.baseUrl, '');
      }

      updatedValue = updatedValue.replace(/%20/g, ' ');

      option.value = updatedValue;

      if (updatedValue === dataFontValue) {
        option.selected = true;
      }
    });
  }

  update_business_name(){
    this.business_name = document.getElementById("user_businessname").value
    this.update_preview()
  }

  update_logo_size() {
    this.logo_size = document.getElementById("user_logo_size").value
    this.update_preview()
  }

  update_title_size(event) {
    this.title_size = event.currentTarget.value
    this.update_preview()
  }

  update_body_size(event) {
    this.body_size = event.currentTarget.value
    this.update_preview()
  }

  update_login_event_mode() {
    this.login_event_mode = document.getElementById("user_login_event_mode").checked
    this.update_preview()
  }

  update_edges(event){
    event.stopPropagation()
    this.edge_optionTargets.forEach(option => option.removeAttribute("checked"))
    const id = event.currentTarget.getAttribute("for")
    document.getElementById(id).setAttribute("checked", "checked")
    this.edge_option_buttonTargets.forEach(button => {
      if (button == event.currentTarget) {
        button.classList.add("selected")
      } else {
        button.classList.remove("selected")
      }
    })
    this.container_edges = document.getElementById(id).value
    this.update_preview()
  }

  update_container_opacity(){
    this.container_opacity = (document.getElementById("user_bgopacity").value / 100).toFixed(2)
    this.update_preview()
  }

  update_container_colour(){
    this.container_colour = document.getElementById("user_bgrgba").getAttribute('value')
    this.update_preview()
  }

  update_position(event){
    event.stopPropagation()
    this.position_optionTargets.forEach(option => option.removeAttribute("checked"))
    const id = event.currentTarget.getAttribute("for")
    document.getElementById(id).setAttribute("checked", "checked")
    this.position_option_buttonTargets.forEach(button => {
      if (button == event.currentTarget) {
        button.classList.add("selected")
      } else {
        button.classList.remove("selected")
      }
    })
    this.logo_position = document.getElementById(id).value
    this.update_preview()
  }

  update_alignment(event) {
    event.stopPropagation()
    this.alignment_optionTargets.forEach(option => option.removeAttribute("checked"))
    const id = event.currentTarget.getAttribute("for")
    document.getElementById(id).setAttribute("checked", "checked")
    this.alignment_option_buttonTargets.forEach(button => {
      if (button == event.currentTarget) {
        button.classList.add("selected")
      } else {
        button.classList.remove("selected")
      }
    })
    this.text_alignment = document.getElementById(id).value
    this.update_preview()
  }


  async update_logo_image(event){
    const file = event.currentTarget.files[0];
    this.logo_url = URL.createObjectURL(file)
    const fileInput = this.logo_pickerTargets;
    if (file && file.type === 'image/heic') {
      try {
        const convertedBlob = await heic2any({ blob: file, toType: 'image/jpeg' });
        const newFile = new File([convertedBlob], file.name.replace(/\.heic$/i, '.jpg'), { type: 'image/jpeg' });
        const reader = new FileReader();
        reader.onload = (e) => {
          this.logo_url = e.target.result;  // Set the data URL as update_logo_image
          this. update_dropify_preview(this.logo_url, event, '96px');
          this.update_preview();  // Call update_preview to update the preview
        };
        reader.readAsDataURL(newFile);
      } catch (error) {
        console.error('HEIC to JPEG conversion error:', error);
      }
    } else {
      // For non-HEIC files, directly update the background image
      const reader = new FileReader();
      reader.onload = (e) => {
        this.logo_url = e.target.result;  // Set the data URL as logo_image
        this.update_preview();  // Call update_preview to update the preview
      };
      reader.readAsDataURL(file);
    }
  }


  select_font_mousedown(event){
    event.preventDefault()
    jQuery(event.currentTarget).blur()
    window.focus()
  }

  select_font_mouseup(event){
    const font_button = document.getElementById("font_selector_dropdown")
    font_button.setAttribute("style", font_button.getAttribute("style").replace("none", "block"))
    jQuery("#font_selector_dropdown").click(function(e) {
      e.stopPropagation()
    })
    jQuery("#font_selector").on('click', function(e) {
      e.stopPropagation()
    })
    jQuery(document.body).click(function(){
      jQuery(".dropdown-menu").hide()
      jQuery(document.body).off('click')
    })
  }

  font_option(event){
    const font_path = event.currentTarget.getAttribute("data-path")
    const font = event.currentTarget.innerText

    jQuery("#font_selector").val(font_path)

    $("#font_selector option:selected")[0].value = event.currentTarget.getAttribute("data-path")

    jQuery(".dropdown-menu").hide()
    jQuery(document.body).off('click')
    this.font = font
    this.titleFont = font
    this.font_path = font_path
    this.update_preview()
  }

  body_font_mouseup(event){
    const font_button = document.getElementById("body_font_selector_dropdown")
    font_button.setAttribute("style", font_button.getAttribute("style").replace("none", "block"))
    jQuery("#body_font_selector_dropdown").click(function(e) {
      e.stopPropagation()
    })
    jQuery("#body_font_selector").on('click', function(e) {
      e.stopPropagation()
    })
    jQuery(document.body).click(function(){
      jQuery(".dropdown-menu").hide()
      jQuery(document.body).off('click')
    })
  }

  body_font_option(event){
    const fontPath = event.currentTarget.getAttribute("data-path")
    const font = event.currentTarget.innerText
    jQuery("#body_font_selector").val(fontPath)

    $("#body_font_selector option:selected")[0].value = event.currentTarget.getAttribute("data-path")

    jQuery(".dropdown-menu").hide()
    jQuery(document.body).off('click')
    this.bodyFont = font
    this.font = this.bodyFont
    this.bodyFontPath = fontPath
    this.font_path = this.bodyFontPath
    this.update_preview()
  }

  body_font_mousedown(event){
    event.preventDefault()
    jQuery(event.currentTarget).blur()
    window.focus()
  }

  btn_font_mouseup(event){
    const font_button = document.getElementById("btn_font_selector_dropdown")
    font_button.setAttribute("style", font_button.getAttribute("style").replace("none", "block"))
    jQuery("#btn_font_selector_dropdown").click(function(e) {
      e.stopPropagation()
    })
    jQuery("#btn_font_selector").on('click', function(e) {
      e.stopPropagation()
    })
    jQuery(document.body).click(function(){
      jQuery(".dropdown-menu").hide()
      jQuery(document.body).off('click')
    })
  }

  btn_font_option(event){
    const font_path = event.currentTarget.getAttribute("data-path")
    const font = event.currentTarget.innerText
    jQuery("#btn_font_selector").val(font_path)

    $("#btn_font_selector option:selected")[0].value = event.currentTarget.getAttribute("data-path")

    jQuery(".dropdown-menu").hide()
    jQuery(document.body).off('click')
    this.font = font
    this.font_path = font_path
    this.btn_font = font
    this.update_preview()
  }

  btn_font_mousedown(event){
    event.preventDefault()
    jQuery(event.currentTarget).blur()
    window.focus()
  }

  input_font_mouseup(event){
    const font_button = document.getElementById("input_font_selector_dropdown")
    font_button.setAttribute("style", font_button.getAttribute("style").replace("none", "block"))
    jQuery("#input_font_selector_dropdown").click(function(e) {
      e.stopPropagation()
    })
    jQuery("#input_font_selector").on('click', function(e) {
      e.stopPropagation()
    })
    jQuery(document.body).click(function(){
      jQuery(".dropdown-menu").hide()
      jQuery(document.body).off('click')
    })
  }

  input_font_option(event){
    const font_path = event.currentTarget.getAttribute("data-path")
    const font = event.currentTarget.innerText
    jQuery("#input_font_selector").val(font_path)
    jQuery(".dropdown-menu").hide()
    jQuery(document.body).off('click')
    this.font = font
    this.font_path = font_path
    this.form_input_font = font
    this.update_preview()
  }

  input_font_mousedown(event){
    event.preventDefault()
    jQuery(event.currentTarget).blur()
    window.focus()
  }

  update_title_colour(event){
    this.text_colour = event.currentTarget.value
    this.update_preview()
  }

  update_body_colour(event) {
    this.body_color = event.currentTarget.value
    this.update_preview()
  }

  update_btn_colour(event) {
    this.btn_color = event.currentTarget.value
    this.update_preview()
  }

  update_btn_text_colour(event) {
    this.btn_text_color = event.currentTarget.value
    this.update_preview()
  }

  update_form_font_size(event) {
    this.form_font_size = event.currentTarget.value
    this.update_preview()
  }

  update_text_colour(event) {
    this.form_text_color = event.currentTarget.value
    this.update_preview()
  }

  update_cta_bg_colour(event) {
    this.cta_bg_color = event.currentTarget.value
    this.update_preview()
  }

  update_accent_colour(){
    this.accent_colour = document.getElementById("user_secondaryhex").value
    this.update_preview()
  }

  update_mode(event) {
    event.stopPropagation()
    this.mode_optionTargets.forEach(option => option.removeAttribute("checked"))
    const id = event.currentTarget.getAttribute("for")
    document.getElementById(id).setAttribute("checked", "checked")
    this.mode_option_buttonTargets.forEach(button => {
      if (button == event.currentTarget) {
        button.classList.add("selected")
      } else {
        button.classList.remove("selected")
      }
    })
    if (id == "user_background_mode_image") {
      document.getElementById("background-image-selector").setAttribute("style", "display:block;")
      document.getElementById("background-colour-selector").setAttribute("style", "display:none;")
      this.mode = "image"
    } else {
      document.getElementById("background-image-selector").setAttribute("style", "display:none;")
      document.getElementById("background-colour-selector").setAttribute("style", "display:block;")
      this.mode = "colour"
    }
    this.update_preview()
  }

  update_background_colour(){
    this.background_colour = document.getElementById("user_background_color").value
    this.update_preview()
  }

  // update_background_image(event){
  //   this.background_image = URL.createObjectURL(event.currentTarget.files[0])
  //   this.update_preview()
  // }

  async update_background_image(event) {
    const file = event.currentTarget.files[0];
    this.background_image = URL.createObjectURL(file)
    const fileInput = this.background_pickerTargets;

    if (file && file.type === 'image/heic') {
      try {
        const convertedBlob = await heic2any({ blob: file, toType: 'image/jpeg' });
        const newFile = new File([convertedBlob], file.name.replace(/\.heic$/i, '.jpg'), { type: 'image/jpeg' });

        const reader = new FileReader();
        reader.onload = (e) => {
          this.background_image = e.target.result;
          this.update_dropify_preview(this.logo_url, event, '88px');
          this.update_preview();
        };
        reader.readAsDataURL(newFile);
      } catch (error) {
        console.error('HEIC to JPEG conversion error:', error);
      }
    } else {
      // For non-HEIC files, directly update the background image
      const reader = new FileReader();
      reader.onload = (e) => {
        this.background_image = e.target.result;
        this.update_preview();
      };
      reader.readAsDataURL(file);
    }
  }


  update_powered_by(){
    this.powered_by_beambox = document.getElementById("checkbox2").checked
    this.update_preview()
  }

  update_dropify_preview(url, event, maxHeight) {
    const dropifyRender = event.srcElement.nextElementSibling.querySelector('.dropify-render');
    if (dropifyRender) {
      const imgElement = document.createElement('img');
      imgElement.src = url;
      imgElement.style.maxHeight = maxHeight;
      dropifyRender.innerHTML = '';
      dropifyRender.appendChild(imgElement);
    }
  }

  update_preview(){
    const content = document.getElementById("mainframe").contentWindow.document;
    const $content = jQuery(content);

    const logoElement = content.querySelector(".logo");
    logoElement.setAttribute("style", `
      width: ${this.logo_size}%;
      margin-left: ${this.logo_position === "left" ? 0 : "auto"};
      margin-right: ${this.logo_position === "right" ? 0 : "auto"};
    `);
    logoElement.setAttribute("src", this.logo_url);

    content.querySelector(".main-cont").setAttribute("style", `
      background: ${this.container_colour.replace('1)', this.container_opacity + ")")};
      border-radius: ${this.radius_calculator()}px;
    `);

    content.querySelector("body style").textContent += `
      @font-face {
        font-family: "${this.font}";
        src: url("${this.baseUrl}${this.font_path}");
      }
    `;

    $content.find(".mask-bg").css('background', this.mode === "image" 
      ? `url('${this.background_image}') no-repeat center center / cover` 
      : this.background_colour
    );

    const loginOverlay = $content.find("#login-event-overlay");
    this.login_event_mode ? loginOverlay.addClass('snow-bg') : loginOverlay.removeClass('snow-bg');

    $content.find("h1, .description").css('align-items', this.text_alignment);
    $content.find("h1").css({
      'font-family': this.titleFont,
      'font-size': this.title_size,
      'color': `#${this.text_colour}`
    });
    $content.find("p").css({
      'font-family': this.bodyFont,
      'font-size': this.body_size,
      'color': `#${this.body_color}`
    });
    $content.find(".b-2").css({
      'font-family': this.btn_font,
      'color': `#${this.btn_text_color}`
    });
    $content.find("#login-buttons a").css('background', `#${this.btn_color}`);
    $content.find(".form-item.active").css('border-left', `3px solid #${this.cta_bg_color}`);
    $content.find("#manual-form input, #manual-form select").css({
      'font-family': this.form_input_font,
      'font-size': this.form_font_size,
      'color': `#${this.form_text_color}`
    });
    $content.find(".submit-button button").css({
      'background': `#${this.cta_bg_color}`,
      'color': `#${this.accent_colour}`
    });
  }

  radius_calculator(){
    if (this.container_edges == "straight"){
      return 0;
    } else if (this.container_edges == "rounded"){
      return 3
    } else {
      return 15
    }
  }

}
