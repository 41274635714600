import { Controller } from "stimulus";
import Rails from "@rails/ujs";

export default class extends Controller {
  static targets = [
    "billingCycle",
    "userPlan",
    "locationsCount",
    "cycleSwitch",
    "p",
    "svg",
    "locationsCounter",
    "monthly",
    "yearly",
    "changePlan",
    "popup1",
    "popup2"
  ];

  connect() {
    this.initializeTargets();
    this.initializeCheckbox();
    this.switchPlan();
  }

  initializeTargets() {
    this.locationsCount = this.locationsCountTarget.value;
    this.billingCycle   = this.billingCycleTarget.value;
    this.userPlan       = this.userPlanTarget.value;
  }

  initializeCheckbox() {
    this.checkbox        = this.cycleSwitchTarget;
    this.hasSearchParams = this.searchParams();

    if (this.hasSearchParams) {
      this.switchToAnnual();
    } else if (this.billingCycle === "yearly") {
      this.checkbox.checked  = false;
      this.checkbox.disabled = true;
    } else {
      this.checkbox.checked  = true;
      this.checkbox.disabled = false;

      setTimeout(() => {
        this.showPopup();
      }, 100);
    }
  }

  switchPlan() {
    this.monthlyTarget.classList.toggle("hidden");
    this.yearlyTarget.classList.toggle("hidden");

    if (this.checkbox.checked) {
      this.pTarget.classList.add("hidden");
      this.svgTarget.classList.add("hidden");
    }
    else {
      this.pTarget.classList.remove("hidden");
      this.svgTarget.classList.remove("hidden");

      if (this.billingCycle !== "monthly" && this.userPlan === "pro") {
        Array.from(this.changePlanTargets).forEach(obj => {
          obj.style.display = "none";
        });
      }
    }

    const locationsText = this.locationsCount === 1 ? " Location" : " Locations";

    Array.from(this.locationsCounterTargets).forEach(target => {
      target.textContent = this.locationsCount + locationsText;
    });
  }

  switchNow() {
    this.popup1Target.classList.remove("in");
    this.checkbox.checked = false;

    this.switchPlan();
  }

  showPopup() {
    this.popup1Target.classList.add("in");
  }

  closePopup() {
    this.popup1Target.classList.remove("in");
  }

  searchParams() {
    const url = new URL(window.location.href);

    return url.searchParams.has("billing_cycle");
  }

  switchToAnnual() {
    this.checkbox.checked = false;

    this.monthlyTarget.classList.toggle("hidden");
    this.yearlyTarget.classList.toggle("hidden");
  }
}
