import { Controller } from "stimulus";
import EmblaCarousel from 'embla-carousel';

export default class EmblaController extends Controller {
  connect() {
    // Grab wrapper nodes
    const rootNode = document.querySelector('.reviewCard-slider');

    // Grab button nodes
    const prevButtonNode = rootNode.querySelector('.embla__prev');
    const nextButtonNode = rootNode.querySelector('.embla__next');

    // Initialize the carousel
    const embla = EmblaCarousel(rootNode);

    // Add click listeners
    prevButtonNode.addEventListener('click', () => {
      embla.scrollPrev();
    });

    nextButtonNode.addEventListener('click', () => {
      embla.scrollNext();
    });

    const options = {
      active: true,
      breakpoints: {
        '(min-width: 768px)': { active: false },
      },
    }

    const emblaApi = EmblaCarousel(rootNode, options);
  }
}
